<div class="container p-sm-auto p-0 pb-4">
    
   <div class="title-div">
        <div class="header" >
          <fa-icon  class="faiconBack" [icon]="faArrowLeft" (click)="goBack()" role="button"></fa-icon>
          <span class="title ms-3">Parcelas</span>
        </div>
        <div class="button-header">
          <a class="btn btn-primary" [routerLink]="['/crear-parcela']"type="button" >Crear parcela</a>
        </div>
      </div>
        <div style="display: flex; align-items: center; margin-left: 20px; margin-right: 20px;">
          <div style="width: 100%;">
            <div class="mb-3 container-searchBar">
              <fa-icon class="searchbar-icon" [icon]="faSearch"></fa-icon>
              <input type="email" (change)="onChange($event)" (keyup)="onChange($event)" class="form-control" id="search" placeholder="Buscar parcela o lugar">
            </div>
            
          </div>
      
        </div>
        <div class="sections-div">
          <div (click)="section=1">
            <div class="section-selected" *ngIf="section==1"></div>
            <div class="section-unselected" *ngIf="section==2"></div>
            <p class="section-name" [class]="section==1 ? 'section-name-selected' : 'section-name'">Parcelas</p>
          </div>

        </div>
      
        <div *ngIf="section===1 && userParcels.length>0"  class="mt-4">
          <div class="entry p-3"  *ngFor="let parcel of userParcels">
            <app-parcel-resume role="button" (click)="seeParcel(parcel)" [parcel]="parcel" class="mt-3"></app-parcel-resume>
          </div>
        </div>
      
        <div *ngIf="section===1 && userParcels.length===0 && !loading">
          <div class="section1 d-flex justify-content-center mt-5" >
            <span >No hay parcelas...</span>
          </div>
        </div>
      
        <!-- <div style="margin-left: calc(50vw - 10px); margin-top: calc(30vh - 14px);" *ngIf="loading"> -->
          <!-- <ion-spinner name="bubbles"></ion-spinner> -->
        <!-- </div> -->
      
        <div *ngIf="section===2">
          <div class="section2">
            <span >Próximamente...</span>
          </div>
        </div>
          <!-- <div class="btn-crear-container">
                <button mat-fab  aria-label="Crear parcela" [routerLink]="['/crear-parcela']">
                  <fa-icon [icon]="faAdd" class="faicon" style="display: flex;"></fa-icon>
                </button>
              
            </div> -->
  
</div>