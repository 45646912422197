<div class="container p-sm-auto p-0" >

    <div class="title-div">
        <div class="header" *ngIf="mostrarPagina">
          <fa-icon role="button" class="faiconBack" [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
          <span class="title">Toma de datos</span>
        </div>
      </div>
      <div  class="p-3 banner" *ngIf="parcel">
        <app-parcel-resume [parcel]="parcel"></app-parcel-resume>
      </div>

      <ng-container *ngIf="mostrarPagina">
      <div class="dates-div">
        <div class="text-div">
          <span >Introduce los datos del análisis del suelo</span>
          <br>
          <span >{{msgType}}</span>
        
          

        </div>
        <div>
          
            <div class="todo"(click)="picker.open()">
                <mat-form-field class="centerCalendar" appearance="outline" [class]=" date== '' || date==undefined ? 'datered':'dategreen'">
                    <mat-label >Fecha análisis</mat-label>
                  <input  (dateInput)="prueba($event)"  matInput  [matDatepicker]="picker"  (change)="date=$event.target.value; foc"  >
                  <mat-datepicker #picker startView="multi-year"
                  ></mat-datepicker>
                </mat-form-field>

              </div>
          </div>
        </div>
      <!-- </div> -->
      <div class="margins" >
        <select class="myFormSelect mt-3 w-100 "[class]=" floorType!== '' ? 'valid':'invalid'" aria-label="Tipo de suelo" placeholder="Tipo de suelo"  (change)="floorType=$event.target.value;">
            <option selected value="">Tipo de suelo</option>
            <option value="franco">Franco</option>
            <option value="arcilloso">Arcilloso</option>
            <option value="arenoso">Arenoso</option>
          </select>
    
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Arena gruesa</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>%</span>
          </div>
          <div class="input" [class]=" agr!== null ? 'valid':''">
            <input [(ngModel)]="agr" type="number" placeholder="00.00">
            <span class="percentage">%</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Arena fina</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>%</span>
          </div>
          <div class="input" [class]="afi!== null ? 'valid':''">
            <input [(ngModel)]="afi" type="number" placeholder="00.00">
            <span class="percentage">%</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Limo</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>%</span>
          </div>
          <div class="input" [class]=" lim!== null ? '':''">
            <input [(ngModel)]="lim" type="number" placeholder="0000">
            <span class="percentage">%</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Arcilla</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>%</span>
          </div>
          <div class="input" [class]=" arc!== null ? 'valid':''">
            <input [(ngModel)]="arc" type="number" placeholder="00.00">
            <span class="percentage">%</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Carbonato cálcico</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>%</span>
          </div>
          <div class="input" [class]=" cca!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="cca" type="number" placeholder="00.00">
            <span class="percentage">%</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Caliza activa</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>%</span>
          </div>
          <div class="input" [class]=" calact!== null ? 'valid':''">
            <input [(ngModel)]="calact" type="number" placeholder="00.00">
            <span class="percentage">%</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Fósforo Olsen</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>ADAS</span>
          </div>
          <div class="input" [class]=" po!== null ? 'valid':''">
            <input [(ngModel)]="po" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Potasio asimilable</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>AcNH4</span>
          </div>
          <div class="input" [class]=" ka1!== null ? 'valid':''">
            <input [(ngModel)]="ka1" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">pH</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>--</span>
          </div>
          <div class="input" [class]=" ph!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="ph" type="number" placeholder="00.00">
            <span class="no-unit"></span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">CE</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>CE 1:05</span>
          </div>
          <div class="input" [class]=" ce!== null ? 'valid':''">
            <input [(ngModel)]="ce"  type="number" placeholder="00.00">
            <span class="inputCE">μμηoσ/χμ</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">CTC</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span></span>
          </div>
          <div class="input" [class]=" ctc!== null ? 'valid':''">
            <input [(ngModel)]="ctc" type="number" placeholder="00.00">
            <span>meq/100gr</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Ca asimilable</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span></span>
          </div>
          <div class="input" [class]=" caa!== null ? 'valid':''">
            <input [(ngModel)]="caa" type="number" placeholder="00.00">
            <span>meq/100gr</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Mg asimilable</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span></span>
          </div>
          <div class="input" [class]=" mga!== null ? 'valid':''">
            <input [(ngModel)]="mga" type="number" placeholder="00.00">
            <span>meq/100gr</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Na asimilable</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span></span>
          </div>
          <div class="input" [class]=" naa!== null ? 'valid':''">
            <input [(ngModel)]="naa" type="number" placeholder="00.00">
            <span>meq/100gr</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Aluminio</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" al!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="al" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Calcio</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" ca!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="ca" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Cobre</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" cu!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="cu" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Fósforo</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" p!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="p" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Hierro</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" fe!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="fe" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Magnesio</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" mg!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="mg" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Manganeso</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" mn!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="mn" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Potasio asimilable</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" ka2!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="ka2" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Sodio</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" na!== null ? 'valid' : 'invalid'" >
            <input [(ngModel)]="na" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Sulfato</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" so!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="so" type="number" placeholder="00.00">
            <span>ppm SO4</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Zinc</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" zn!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="zn" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Boro</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Mehlich 3 E ICP</span>
          </div>
          <div class="input" [class]=" b!== null ? 'valid':'invalid'">
            <input [(ngModel)]="b" type="number" placeholder="00.00">
            <span>ppm</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">MO oxidable</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Met QP</span>
          </div>
          <div class="input" [class]=" moox!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="moox" type="number" placeholder="00.00">
            <span class="percentage">%</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Nitrógeno elemental</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Analizador elemental CNS</span>
          </div>
          <div class="input" [class]=" nele!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="nele" type="number" placeholder="00.00">
            <span class="percentage">%</span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">C/N</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Calculado</span>
          </div>
          <div class="input" [class]=" cnc!== null ? 'valid':''">
            <input [(ngModel)]="cnc" type="number" placeholder="00.00">
            <span class="no-unit"></span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">C/N</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span>Analitica</span>
          </div>
          <div class="input" [class]=" cna!== null ? 'valid' : 'invalid'">
            <input [(ngModel)]="cna" type="number" placeholder="00.00">
            <span class="no-unit"></span>
          </div>
        </div>
      </div>
      <div class="entry">
        <div class="text">
          <span class="fst">Densidad</span>
        </div>
        <div class="d-flex justify-content-between;">
          <div class="select">
            <span></span>
          </div>
          <div class="input" [class]=" d!== null ? 'valid':''">
            <input [(ngModel)]="d" type="number" placeholder="00.00">
            <span>mg/l</span>
          </div>
        </div>
      </div>
      <div class="btn-save">

        <button class="button" expand="block" fill="clear" (click)="save()" [disabled]="loading">
          Guardar
        </button>
      </div>
     
    </ng-container>

</div>