<app-menu [home]="false"></app-menu>

<div class="container padding">
    <h1 class="title-terms">Aviso Legal</h1>
    <p>De conformidad con lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), se informa a los usuarios que el presente aviso legal regula el acceso y uso del sitio web (en adelante, el "Sitio web"), propiedad de Internor Ingenieros, con NIF 16542328S.<p>
    <p class="sub-title-terms">Objeto</p>
    <p>El presente aviso legal tiene por objeto establecer las condiciones de uso del Sitio web, así como informar a los usuarios sobre los derechos, responsabilidades y obligaciones que se derivan de su utilización.</p>
    <p class="sub-title-terms">Aceptación y modificaciones</p>
    <p>El acceso y uso del Sitio web atribuyen la condición de usuario e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones contenidas en este aviso legal. Internor Ingenieros se reserva el derecho de modificar, actualizar o eliminar total o parcialmente el contenido del Sitio web, así como de modificar las condiciones de acceso y uso sin previo aviso. Se recomienda al usuario revisar periódicamente el contenido de este aviso legal para estar al tanto de cualquier cambio.</p>
    <p class="sub-title-terms">Propiedad intelectual e industrial</p>
    <p>Internor Ingenieros es titular de todos los derechos de propiedad intelectual e industrial del Sitio web, incluyendo pero no limitando a su diseño, textos, gráficos, imágenes, marcas, logotipos, nombres de dominio y otros elementos que lo componen. Queda expresamente prohibida cualquier reproducción, distribución, comunicación pública, transformación o cualquier otro acto de explotación, total o parcial, del contenido del Sitio web sin la autorización previa y por escrito de Internor Ingenieros.</p>
    <p class="sub-title-terms">Responsabilidad del usuario</p>
    <p>El usuario se compromete a utilizar el Sitio web de forma diligente, correcta y lícita, y a no realizar ninguna acción que pueda causar daños o perjuicios a Internor Ingenieros o a terceros. El usuario será responsable de cualquier daño o perjuicio que pueda derivarse del incumplimiento de las presentes condiciones o de la utilización indebida del Sitio web.</p>
    <p class="sub-title-terms">Responsabilidad de Internor Ingenieros</p>
    <p>Internor Ingenieros se esforzará en mantener el Sitio web en buen estado de funcionamiento y actualizado. No obstante, no se garantiza la disponibilidad continua y sin interrupciones del Sitio web, ni su total ausencia de errores. Internor Ingenieros no será responsable de los daños o perjuicios que puedan derivarse de la falta de disponibilidad o continuidad del Sitio web, ni de los errores en su contenido o de la transmisión de virus o programas maliciosos a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.</p>
    <p class="sub-title-terms">Protección de datos personales</p>
    <p>El tratamiento de los datos personales de los usuarios se realizará de conformidad con la normativa vigente en materia de protección de datos personales. Para obtener más información, consulte nuestra Política de Privacidad.</p>
    <p class="sub-title-terms">Ley aplicable y jurisdicción</p>
    <p>El presente aviso legal se regirá e interpretará de acuerdo con la legislación española. Cualquier controversia que pueda surgir en relación con el Sitio web o con la interpretación y aplicación del presente aviso legal será sometida a los juzgados y tribunales competentes de acuerdo con la legislación española.</p>
    <p>Si tiene alguna pregunta o consulta sobre este aviso legal, puede ponerse en contacto con nosotros a través de oficina@internoringenieros.es.</p>
    <p>Internor Ingenieros - NIF 16542328S</p>
</div>
