import { Component } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AnalysisService } from 'src/app/services/analysis.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FertilizerService } from 'src/app/services/fertilizer.service';
import { InterpretationService } from 'src/app/services/interpretation.service';
import { ParcelsService } from 'src/app/services/parcels.service';

import { faArrowLeft, } from "@fortawesome/pro-regular-svg-icons";
import { faCaretDown, } from "@fortawesome/pro-solid-svg-icons";
import { Location } from '@angular/common';



@Component({
  selector: 'app-data-analysis',
  templateUrl: './data-analysis.component.html',
  styleUrls: ['./data-analysis.component.scss']
})
export class DataAnalysisComponent {
  date = new Date();
  parcel: any;
  estadoNuevo: boolean = false;
  interpretation: any;
  analysis: any;
  section: number = 1;
  aux: any[];
  yearSelected: any = this.date.getFullYear() + "";
  calcFert: any;
  paid: boolean = false;
  firstTime: boolean = true;
  abonoAUX: any = {}
  selectedDate: Date;
  datepicker: MatDatepicker<Date>;
  iconos = {
    faArrowLeft,
    faCaretDown,
  }




  constructor(/*private pickerCtrl: PickerController,*/ private router: Router, private auth: AuthenticationService,
    private analysisService: AnalysisService, private parcelService: ParcelsService, /*private location: Location,*/
    private interService: InterpretationService, private route: ActivatedRoute, /*private mapPage: MapHomePage,*/
    private fertService: FertilizerService, private location: Location) {

    const state = this.router.getCurrentNavigation().extras.state;

    
    if (state) {
      if (state['year']) {
        this.yearSelected = state['year'];

        this.yearSelected = this.date.getFullYear() + "";
      }
      if(state['history']){
        this.yearSelected = state['year'];
      }
      if (state['estadoNuevo']) {
        this.estadoNuevo = true;
      }
    }
  }

  ngOnInit() {
    this.loadData();
    this.firstTime = false;
  }


  selectYear(fecha: Date) {
    this.yearSelected = fecha.getFullYear();
    //change param year in url


    this.loadData();
  }



  loadData() {
    this.route.queryParams.subscribe(params => {


      this.parcelService.getParcelaById(params['parcel']).subscribe(parcel => {
     
        
        if (!parcel) {
          this.goBack();
        }

        
        this.parcel = parcel;
        this.analysisService.getAnalysisParcela({ ano: this.yearSelected, parcelaId: this.parcel.id }).subscribe(analysis => {
          

          try {

            this.date = analysis.fecha.date;
          } catch (err) {
            this.date = null;
          }

          try {
            this.analysis = analysis;

            if (analysis === 'No existen análisis para este año') {
              this.paid = false;
            } else {

              this.paid = analysis.payed;
            }
          } catch (err) {
            this.paid = false;

          }


          let data = {
            ano: this.yearSelected,
            parcelaId: parcel.id
          }
          // this.analysisService.getAnalysisParcela({ano:this.yearSelected, parcelaId:this.parcel.id }).subscribe(analysis => {
          // 

          this.paid = analysis.payed;
          this.interService.getInterpretationParcela(data).subscribe(dataRe => {
            // 
            if (dataRe === 'No existen análisis para este año') {
              this.interpretation = [];
              this.calcFert = [];

            } else {
              this.interpretation = Object.keys(dataRe).map(function (index) {
                let item = dataRe[index];
                return item;
              });
              this.fertService.getAbonadoParcela(data).subscribe(dataRe => {
                // 

                if (dataRe === 'No existe este calculo de abonado') {
                  this.calcFert = [];

                } else {
                  this.paid = true;
                  var self = this;
                  this.calcFert = Object.keys(dataRe).map(function (index) {
                    let item = dataRe[index];
                    return item;
                  });
                }
                //this.interpretation=dataRe;
                //this.aux= dataRe.AnalisisFertilidad;


              });
            }
            //this.interpretation=dataRe;
            //this.aux= dataRe.AnalisisFertilidad;


          });
        });

      });
      // })
    });




    // });
  }

  convertToArray(item: any) {
    let array2 = [];
    for (let key in item) {
      //
      if (typeof item[key] === 'object') {
        array2.push(item[key]);
      } else {
        let cosa = {
          prop: key,
          value: item[key]
        }
        array2.push(cosa);
      }
    }


    return array2;
  }



  goPay() {



    this.auth.getUsersMe().subscribe(user => {
      let navigationExtras: NavigationExtras = {
        state: {
          email: user.email,
          analysis: this.analysis.id,
          parcel: this.parcel.id,
          year: this.yearSelected
        }
      };

      this.router.navigate(['/providers'], navigationExtras);


    })
  }

  goBack() {



    if (this.estadoNuevo) {
      let id =
        this.parcel.provsigpac + '.' +
        this.parcel.munsigpac + '.' +
        this.parcel.parcela + '.' +
        this.parcel.poligono

      let navigationExtras: NavigationExtras = {
        queryParams: {
          parcel: id
        }
      }
      this.router.navigate(['/parcel-detail'], navigationExtras);
    } else {

      this.location.back();
    }

  }

  getSectionInter(index: any) {
    switch (index) {
      case 0:
        return 'Análisis de fertilidad del suelo';
        break;
      case 1:
        return 'Resultados de las bases en mequiv/100gr';
        break;
      case 2:
        return 'Elementos menores';
        break;
      case 3:
        return 'Relación entre cationes';
        break;
      case 4:
        return 'Porcentaje de saturación de bases';
        break;

      default:
        return '';
        break;
    }
  }

  getSectionCalc(index: any) {
    switch (index) {
      case 0:
        return 'Características del cultivo';
        break;
      case 1:
        return 'Abonos principales';
        break;
      case 2:
        return 'Otros minerales';
        break;
      case 3:
        return 'Elementos menores';
        break;
      case 4:
        return 'Tipos de abonos';
        break;
      case 5:
        return 'Uds necesarias por hectárea';
        break;

      case 6:
        return 'Uds necesarias totales';
        break;
      case 7:
        return 'Uds aportadas totales';
        break;

      case 8:
        return 'Kg necesarios por tipo de abono';
        break;

      case 9:
        return 'Total finca';
        break;

      case 10:
        return 'Ratio/ha';
        break;

      case 11:
        return 'Ratio/fanega';
        break;



      default:
        return '';
        break;
    }
  }


  onYearSelected(event: any) {
    const year = event.getFullYear();
    this.selectedDate = new Date(year, 0, 1);
    this.datepicker.close();
    // localStorage.setItem('year', year.toString());
    

  }
  

}
