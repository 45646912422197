import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
 import * as bcrypt from 'bcryptjs';
import { Dialog } from '@angular/cdk/dialog';
import { MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { NotificacionDialogComponent } from '../notificacion-dialog/notificacion-dialog.component';
// const bcrypt = require('bcryptjs');


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  public changePasswordForm: FormGroup;
  public errorMsg: string[] = [];
  user: any;
  bcrypt = bcrypt;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  renewLostPass: boolean = false;
  init: boolean = false;
  email: string = "";
  hide1: boolean = false;
  hide2: boolean = false;
  hide3: boolean = false;
  msgCustom1: string = '';
  msgCustom2: string = '';
  msgCustom0: string = 'La contraseña debe tener al menos 8 caracteres';




  constructor(private formBuilder: FormBuilder, private auth: AuthenticationService, private router: Router,
     private dialogRef: MatDialogRef<ChangePasswordComponent>, private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.changePasswordForm = this.formBuilder.group({
      newpass1: ['', [Validators.required, Validators.minLength(6)]],
      newpass2: ['', [Validators.required, Validators.minLength(6)]],



    });




    try {


      if (this.data.email) {
        this.email = this.data.email;
      }
      if (this.data.email && this.data.token) {
        this.renewLostPass = true;
      } else {
        this.renewLostPass = false;
        this.changePasswordForm = this.formBuilder.group({
          newpass1: ['', [Validators.required, Validators.minLength(8)]],
          newpass2: ['', [Validators.required, Validators.minLength(8)]],
          oldPass: ['', [Validators.required, Validators.minLength(8)]],
        });

      }

    } catch (error) {

      this.renewLostPass = false;
      this.changePasswordForm = this.formBuilder.group({
        newpass1: ['', [Validators.required, Validators.minLength(8)]],
        newpass2: ['', [Validators.required, Validators.minLength(8)]],
        oldPass: ['', [Validators.required, Validators.minLength(8)]],


      });
    }





  }
  renewPassword() {
  
    if(this.changePasswordForm.get('oldPass').value.length < 8 || this.changePasswordForm.get('newpass1').value.length < 8 || 
    this.changePasswordForm.get('newpass2').value.length < 8){
      this.openDialog('0.25s', '0.25s', 'La contraseña  debe tener al menos 8 caracteres', 1);
      return;
    }
     




    if (this.changePasswordForm.get('newpass1').value !== this.changePasswordForm.get('newpass2').value) {
      this.openDialog('0.25s', '0.25s', 'Las contraseñas no coinciden', 1);
      this.changePasswordForm.get('newpass1').setErrors({ 'incorrect': true });
      this.changePasswordForm.get('newpass1').setValue('');

      this.changePasswordForm.get('newpass2').setErrors({ 'incorrect': true });
      this.changePasswordForm.get('newpass2').setValue('');


      return;
    }



    if (!this.changePasswordForm.valid) {
      this.init = true;
      return;
    }




    this.auth.getUsersMe().subscribe(user => {
      this.user = user;



      if (this.changePasswordForm.get('oldPass').valid) {
        this.auth.login(this.user.email, this.changePasswordForm.get('oldPass').value).subscribe(user => {

          if (user.code != 401) {
            let salt = this.bcrypt.genSaltSync(10);
            var hash = this.bcrypt.hashSync(this.changePasswordForm.get('newpass1').value, salt);

            let usr = { password: hash }
            this.auth.updateUser(this.user.id, usr).subscribe(userUpdt => {
              this.openDialog('0.25s', '0.25s', 'Contraseña actualizada', 0);
              this.dialogRef.close();

            }), error => {

              this.openDialog('0.25s', '0.25s', 'No se pudo actualizar', 1);
            };

          } else {
            this.openDialog('0.25s', '0.25s', 'Contraseña antigua incorrecta', 1);
            return;
          }

        }, error => {
          this.changePasswordForm.get('oldPass').setErrors({ 'incorrect': true });
          this.changePasswordForm.get('oldPass').setValue('');


          this.openDialog('0.25s', '0.25s', 'Contraseña antigua incorrecta', 1);
          return;
        })

      }
    }
    );
  }



  renewLostPassword() {
    if(this.changePasswordForm.get('newpass1').value.length < 8 || this.changePasswordForm.get('newpass2').value.length < 8){

      this.openDialog('0.25s', '0.25s', 'La contraseña  debe tener al menos 8 caracteres', 1);
      return;
    }
    if (this.changePasswordForm.get('newpass1').value === this.changePasswordForm.get('newpass2').value) {

      let salt = this.bcrypt.genSaltSync(10);
      var hash = this.bcrypt.hashSync(this.changePasswordForm.get('newpass1').value, salt);

      this.auth.passReset(this.data.email, this.data.token, hash).subscribe(response => {
        

        this.openDialog('0.25s', '0.25s', response.message, 0, response.title);
        this.dialogRef.close();
      }, error => {
        this.openDialog('0.25s', '0.25s', 'No se pudo actualizar', 1);
      }
      );
    } else {
      // this.errorMsg.push('Las nuevas contraseñas no coinciden');
      this.openDialog('0.25s', '0.25s', 'Las contraseñas no coinciden', 1);
      return
    }

    if (!this.changePasswordForm.valid) {
      this.openDialog('0.25s', '0.25s', 'Por favor, rellene todos los campos', 1);
      this.init = true;
      return;
    }
    

  }

  changePassword() {
    if (this.renewLostPass) {
      this.renewLostPassword();
    } else {
      this.renewPassword();
    }


  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, mensaje, botones, title = 'Advertencia'): void {
    this.dialog.open(NotificacionDialogComponent, {
      data: { msg: mensaje, botones: botones, title: title },
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  comprobarPass(nombre: string) {
    let newpass1 = this.changePasswordForm.get('newpass1');
    let newpass2 = this.changePasswordForm.get('newpass2');
    let oldPass = this.changePasswordForm.get('oldPass');
  
    
    let tieneError =  newpass1.value !== newpass2.value && newpass1.touched && newpass2.touched;
    if(nombre=='newpass1' && newpass1.value.length < 8 && newpass1.touched){
      this.msgCustom1='La contraseña debe tener al menos 8 caracteres';
      return true;
    }
    if(nombre=='newpass2' && newpass2.value.length < 8 && newpass2.touched){
      this.msgCustom2='La contraseña debe tener al menos 8 caracteres';
      return true
    }
    
     
        if(tieneError){
          this.msgCustom1='La contraseña no coinciden';
          this.msgCustom2='La contraseña no coinciden';
          if(nombre=='newpass1' && newpass1.value.length < 8 && newpass1.touched){
            this.msgCustom1='La contraseña debe tener al menos 8 caracteres';}
          if(nombre=='newpass2' && newpass2.value.length < 8 && newpass2.touched){
            this.msgCustom2='La contraseña debe tener al menos 8 caracteres';          }
         
         
          this.changePasswordForm.get('newpass1').setErrors({ 'incorrect': true });
          this.changePasswordForm.get('newpass2').setErrors({ 'incorrect': true });

        }else{
          this.changePasswordForm.get('newpass1').clearAsyncValidators();
          this.changePasswordForm.get('newpass2').clearAsyncValidators();
          this.changePasswordForm.get('newpass1').updateValueAndValidity();
          this.changePasswordForm.get('newpass2').updateValueAndValidity();
    
        }
        return tieneError;
      }
}
