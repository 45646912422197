<div class="container p-sm-auto p-0" *ngIf="mostrarPagina">

        <div class="title-div">
          <div class="header">
            <fa-icon class="faiconBack"  [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
            <span class="title">Editar parcela</span>
          </div>
          <!-- <fa-icon style="font-size: 24px; margin-right: 20px;" [icon]="['far','times']"></fa-icon> -->
        </div>
        <div class="parcel-draw-div">
          <img src="../../../assets/provisional/parcela.png" alt="">
        </div>
        <div class="inputs">
      
      
            <div class="input-div">
         
              <!-- <input matInput placeholder="Ex. Pizza" value="Sushi"> -->
              <input type="text" placeholder="Nombre parcela" [(ngModel)]="nombre" >
            </div>
         
          <div class="input-div">
            <input type="text" placeholder="Localidad" [(ngModel)]="localidad">
          </div>
          <div class="input-div">
            <input type="text" placeholder="Paraje" [(ngModel)]="paraje">
          </div>
          <div class="input-div">
            <select class="myFormSelect" aria-label="Default select example" (change)="clickCrop($event)">
              <option [selected]="item.cultivo==cultivo" *ngFor="let item of cultivos"  [value]="[item.id,item.cultivo]">{{item.cultivo}}</option>
            </select>
          </div>
          
          <div class="input-div">
            <input type="text" placeholder="Referencia catastral" [(ngModel)]="refcatastral">
          </div>
      
          <div class="input-div numeric-input">
            <input type="text" placeholder="Superficie catastral" [(ngModel)]="superficatastral">
            <div>
              <span>HA</span>
            </div>
          </div>
      
         
          
          <div class="input-div">
            <select class="myFormSelect" aria-label="Método de cultivo" (change)="clickMethod($event)">
            <option [selected]="metodo=='Secano'"  value="Secano">Secano</option>
            <option  [selected]="metodo=='Regadío'"   value="Regadío">Regadío</option>
            </select>
          </div>
          
      
      
          <div class="input-div">
            <input type="number" placeholder="Superficie de siembra" [(ngModel)]="supersiembra">
          </div>
          <div class="input-div">
            <input type="number" placeholder="Densidad" [(ngModel)]="densidad">
          </div>
          <div class="input-div">
            <input type="number" placeholder="Capa arable" [(ngModel)]="capaarable">
          </div>
          <div class="input-div">
            <input type="number" placeholder="Producción esperada" [(ngModel)]="prodesperada">
          </div>
          <div class="input-div">
            <input type="text" placeholder="Provincia" [(ngModel)]="provincia">
          </div>
          <div class="input-div">
            <input type="text" placeholder="Municipio" [(ngModel)]="municipio">
          </div>
          <div class="input-div">
            <input type="text" placeholder="Agregado" [(ngModel)]="agregado">
          </div>
          <div class="input-div">
            <input type="text" placeholder="Código de zona" [(ngModel)]="codzona">
          </div>
          <div class="input-div">
            <input type="text" placeholder="Recinto" [(ngModel)]="recinto">
          </div>
          <div class="input-div">
            <input type="number" placeholder="Parcela (SigPac)" [(ngModel)]="parcela">
          </div>
          <div class="input-div">
            <input type="number" placeholder="Polígono (SigPac)" [(ngModel)]="poligono">
          </div>
          <div class="input-div">
            <input type="number" placeholder="Provincia (SigPac)" [(ngModel)]="provsigpac">
          </div>
          <div class="input-div">
            <input type="number" placeholder="Municipio (SigPac)" [(ngModel)]="munsigpac">
          </div>
        </div>
        <div class="btn-save">

          <button class="button" expand="block" fill="clear" (click)="edit()">
            Guardar
          </button>
        </div>

      
</div>