<h1 *ngIf="!register" mat-dialog-title>Iniciar Sesión</h1>
<h1 *ngIf="register"  mat-dialog-title>Registro</h1>

<div mat-dialog-content *ngIf="!register">
  <div class="register">
    <a (click)="register=true" role="button">¿No tienes cuenta?</a>
  </div>
  <form [formGroup]="loginForm" enctype="application/x-www-form-urlencoded" class="form-login">
    <div class="form-login-content">
     
      <div  class="content-input-form-login-dialog">
        <input id="username" type="email" class="input-form-login-dialog" matInput  placeholder="Email" formControlName="username" [ngClass]="{error:comprobarCampo('username')}">
        <span class="msgError" *ngIf="comprobarCampo('username')">Introduce el email</span>
      </div>
      <div class="content-input-form-login-dialog">

        <input id="password" class="input-form-login-dialog" matInput [type]="hide ? 'text' : 'password'" matInput placeholder="Contraseña" autocomplete="off" formControlName="password" [ngClass]="{error:comprobarCampo('password')}" (keydown.enter)="login()">
        <span class="msgError" *ngIf="comprobarCampo('password')">Introduce la contraseña</span>
        <div class="toogleHide">
          <button  type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility':'visibility_off'}}</mat-icon>
          </button>
        </div>
      </div>
    
      <div class="enlace">
        <a role="button" (click)="enviarEmail()" role="button" >¿Has olvidado la contraseña?</a>
      </div>
      <div class="content-input-form-login-dialog">
        <button  (click)="login()">Iniciar Sesión</button>
      </div>
      <!-- <div class="content-input-form-login-dialog ">
        <button class="different" (click)="onNoClick()">Cancelar</button>
      </div> -->
    </div>
  </form>
</div>

<div mat-dialog-content *ngIf="register">
  <div class="register">
    <a (click)="register=false" role="button">¿Tienes cuenta?</a>
  </div>
  <form [formGroup]="registerForm" enctype="application/x-www-form-urlencoded" class="form-login">
    <div class="form-login-content">
     
      <div  class="content-input-form-login-dialog">
        <input id="email" class="input-form-login-dialog" matInput  placeholder="Email" formControlName="email" autocomplete="off" [ngClass]="{error:registerForm.get('email').errors!=null && registerForm.get('email').touched}">
        <span class="msgError" *ngIf="registerForm.get('email').errors!=null && registerForm.get('email').touched">Introduce el email</span>
      </div>
      <div class="content-input-form-login-dialog">
        <input id="pass" class="input-form-login-dialog"   [type]="hide1 ? 'password' : 'text'" matInput placeholder="Contraseña" autocomplete="off"  formControlName="pass" [ngClass]="{error:comprobarPass('pass')}">
        <span class="msgError" *ngIf="comprobarPass('pass')">{{customMsg1}}</span>
        <div class="toogleHide">
          <button type="button" mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
            <mat-icon>{{hide1 ? 'visibility_off':'visibility'}}</mat-icon>
          </button>
        </div>
      </div>
      <div class="content-input-form-login-dialog">
        <input id="pass2" class="input-form-login-dialog" matInput  [type]="hide2 ? 'password' : 'text' " matInput placeholder="Repite contraseña" autocomplete="off" formControlName="pass2" [ngClass]="{error:comprobarPass('pass2')}">
        <span class="msgError" *ngIf="comprobarPass('pass2')">{{customMsg2}}</span>
        <div class="toogleHide">
          <button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
            <mat-icon>{{hide2 ? 'visibility_off':'visibility'}}</mat-icon>
          </button>
        </div>
      </div>
      <div class="content-input-form-login-dialog">
        <button (click)="registerUser()">Registrarme</button>
      </div>
    </div>
  </form>
</div>
