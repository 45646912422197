import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import jwt_decode from "jwt-decode";
@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss']
})
export class HeaderAdminComponent {

  imageSrc!: string;
  currentUser!:   any;
  isAdmin = false;

  constructor(public dialog: MatDialog, 
    private router: Router, 
    // private userService: UsersService,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    var token = localStorage.getItem('jwt_token');
    if(token != null) {
      var decoded: any = jwt_decode(token);
      if(token && decoded && decoded.username) {
        decoded.roles.length > 1 ? this.isAdmin = true : this.isAdmin = false;
        this.authenticationService.getUsersMe().subscribe(id => {
          if(id != null && id != false) {
            this.authenticationService.getUser(id)
            .pipe(first())
            .subscribe(
              data => {
                this.currentUser = data;
                if(data.imagen) {
                  this.imageSrc = AppSettings.MEDIA + '/perfiles/' + data.imagen;
                }
              }
            );
          }
        });
      }
    }
  }

  openProfile(): void {
    this.router.navigate(['/admin/profile']);
    // const dialogRef = this.dialog.open(ProfileComponent, {
    //   width: '400px',
    // });  
  }

  backFront(): void {
    this.router.navigate(['/']).then(function() {
      location.reload(); 
    });  
  }

  logout(): void {
    this.authenticationService.logout();
  }


}
