<div class="container">

  <div class="title-div">
    <div class="header">
      <!-- <fa-icon style="font-size: 21px; margin-left: 16px;" [icon]="['far','arrow-left']" (click)="goBack()"></fa-icon> -->
      <span class="title mb-4">Contacto</span>
    </div>
  </div>

  <div class="form-div">

    <form [formGroup]="contactForm" class="form-login">



      <div class="input-div col-12 col-sm-5"   [ngClass]="{'invalid-input' : comprobarCampo('name'),   'valid-input' : contactForm.controls['name'].errors==null }">
        <input class="pl15" placeholder="Nombre" 
          type="email" formControlName="name">
      </div>

      <div class="d-flex flex-column flex-sm-row justify-content-between"  >
        <div class="input-div col-12 col-sm-5"  [ngClass]="{'invalid-input' : comprobarCampo('email'),   'valid-input' : contactForm.controls['email'].errors==null}">
          <input class="pl15" placeholder="Correo electrónico"
            type="text" formControlName="email">
        </div>


        <div class="input-div col-12 col-sm-6"   [ngClass]="{'invalid-input' : comprobarCampo('subject'),   'valid-input' : contactForm.controls['subject'].errors==null}">
          <input class="pl15" placeholder="Asunto"
            type="text" formControlName="subject">
        </div>
      </div>
  
      <div class="textarea">

        <textarea  placeholder="Observaciones"
        [ngClass]="{'invalid-input' : comprobarCampo('body'),   'valid-input' : contactForm.controls['body'].errors==null}"
        type="text" formControlName="body"></textarea>
      </div>
      


        
      <div class="button-header">
        <button class="btn btn-primary" type="button" (click)="send()">Enviar</button>
      </div>


    </form>
  </div>


</div>