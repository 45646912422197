import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ElementsService {
  // const url = `${ AppSettings.API_URI }/${nombreTabla}`;
  constructor(private http: HttpClient) { }



  getElemento(nombreTabla:String,id){
    const url = `${ AppSettings.API_URI }/api/${nombreTabla}/${id}`;
  return  this.http.get<any>(url) .pipe(
      map((data: any) => {
        return data;
      }
    )
    )
    
    



  }
  getElementos(nombreTabla:String,{items,page}){
  
    
    const url = `${ AppSettings.API_URI }/api/${nombreTabla}?page=${page}&items=${items}`;
 return   this.http.get<any>(url).pipe(
      map((data: any) => {
        return data;
      }
    )
    )

//     1	Muy bajo	50	Muy bajo, habitualmente asociado a suelos muy calizos o yesosos	
// Optimo para muchos cultivos


  }
  setElemento(nombreTabla:String,id,jsonForm){
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/${nombreTabla}/${id}`;
  return  this.http.put<any>(url, jsonForm,formHeaders).pipe(
      map((data: any) => {
     return data
      
    })
    );


    

  }
  
}
