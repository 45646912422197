import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ParcelsService } from 'src/app/services/parcels.service';


import { faArrowLeft, faPenCircle,faClipboardList,faSearch,faAdd} from "@fortawesome/pro-regular-svg-icons";
import { faCircleExclamation} from "@fortawesome/pro-solid-svg-icons";
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-parcelas',
  templateUrl: './parcelas.component.html',
  styleUrls: ['./parcelas.component.scss']
})
export class ParcelasComponent {
faArrowLeft=faArrowLeft;
faPenCircle=faPenCircle;
faSearch=faSearch;
faClipboardList=faClipboardList;
faCircleExclamation=faCircleExclamation;
faAdd=faAdd;

  section:number=1;


  backupParcels:any = [];
  // parcels:any = [
  //   {
  //     parcela: 'Parcela 1',
  //     reference: '22009504',
  //     available: 'disponible',
  //     hectare: 120,
  //     type: 'Secano',
  //     date: '12/02/2023',
  //     polygon: 'Nombre polígono',
  //   },
  //   {
  //     parcela: 'Parcela 2',
  //     reference: '22009504',
  //     available: 'disponible',
  //     hectare: 120,
  //     type: 'Secano',
  //     date: '12/02/2023',
  //     polygon: 'Nombre polígono',
  //   }
  // ];

  userParcels:any[] =[];

  loading: boolean = false;

  constructor(/*private modalController: ModalController*/ private router: Router, private parcelService: ParcelsService, private auth: AuthenticationService, private meta:Meta,private title:Title) {
    this.title.setTitle('Parcelas - Controla tus cultivos y aumenta rendimientos.');
    if(this.meta.getTag('name=description')){
      this.meta.updateTag({ name: 'description', content: 'Desde parcelas, puedes ver todas tus parcelas en un solo lugar. Gestiona fácilmente tus cultivos y toma decisiones informadas para mejorar tus rendimientos.' });
    }else{
      this.meta.addTag({ name: 'description', content: 'Desde parcelas, puedes ver todas tus parcelas en un solo lugar. Gestiona fácilmente tus cultivos y toma decisiones informadas para mejorar tus rendimientos.' });
    }
  }


  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    
  
    this.loading=true;
    this.userParcels=[];
    this.backupParcels=[];
    // this.modalController.dismiss();
    this.backupParcels = this.userParcels;
    this.auth.getUsersMe().subscribe(user => {
      this.auth.getUser(user.id).subscribe(user => {
        user.parcelas.forEach(parcela => {
          
          this.parcelService.getParcelaById(parcela.split('\/')[3]).subscribe(parcel => {
            this.userParcels.push(parcel);
            this.loading=false;
          })
        });
      });
    });
  }

  seeParcel(parcel: any){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        parcel: parcel.provsigpac + '.' + parcel.munsigpac + '.' + parcel.parcela + '.' + parcel.poligono+'.'+parcel.id,
        
      }
    };
    this.router.navigate(['/parcel-detail'], navigationExtras);
  }

  ngOnChanges(event) {
    const filteration = event.target.value;

    this.userParcels = this.filterItems(filteration);
    if (filteration.length === 0) {
         this.userParcels = this.backupParcels;
    }
}

  filterItems(searchTerm) {
    return this.backupParcels.filter(item => {
        return (item.nombre || '').toLowerCase()
           .indexOf((searchTerm || '').toLowerCase()) > -1;
    });
}

goBack() {
  this.router.navigate(['/perfil']);
  // this.location.back();
  // this.location.hostname;}
}

onChange(event){
  const filteration = event.target.value;

  this.userParcels = this.filterItems(filteration);
  if (filteration.length === 0) {
    this.userParcels = this.backupParcels;
  }
  
}

}
