import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class ParcelsService {

  constructor(private http: HttpClient, private common: CommonService) { }

  getParcelas() {
    const url = `${ AppSettings.API_URI }/api/parcelas`;
    return this.common.getDataHydra(url);
  }

  getParcelaById(parcelaId: any) {
    return this.http.get<any>(AppSettings.API_URI + '/api/parcelas/'+parcelaId).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
   
  }

  getParcelaSigpac(data: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/parcelasby`;
    return this.http.post<any>(url, data, formHeaders).pipe(
      map(data => {
        if(data) {
         
          
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  postParcel(parcel: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/api/parcelas`;
    return this.http.post<any>(url, parcel, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  putParcel(parcel: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/api/parcelas/`+parcel.id;
    return this.http.put<any>(url, parcel, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }
}
