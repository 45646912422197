<div class="container p-sm-auto p-0">

  <ng-container *ngIf="parcel">



    <div class="title-div">
      <div class="header">
        <fa-icon class="faiconBack" [icon]="iconos.faArrowLeft" (click)="goBack()"
          role="button"></fa-icon>
        <span class="title">Análisis</span>
      </div>
    </div>
    <div class="banner p-3">
      <app-parcel-resume [parcel]="parcel"></app-parcel-resume>
    </div>
    <div class="dates-div">
      <div class="date-div">
        <ng-container *ngIf="date!=null">
          <span >Fecha análisis</span>
          <span >{{date|date:'d/M/y'}}</span>
        </ng-container>

      </div>








      <div class="select" (click)="picker.open()">
        <span>{{yearSelected}}</span>
        <fa-icon class="ms-2" [icon]="iconos.faCaretDown"></fa-icon>
        <mat-form-field class="centerCalendar" appearance="fill">
          <input matInput [matDatepicker]="picker">
          <mat-datepicker #picker startView="multi-year"
            (yearSelected)="selectYear($event);picker.close()"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="sections-div pb-4">
      <div (click)="section=1">
        <div class="section-selected" *ngIf="section==1"></div>
        <div class="section-unselected" *ngIf="section==2"></div>
        <p class="section-name" role="button" [class]="section==1 ? 'section-name-selected' : 'section-name'">
          Interpretaciones</p>
      </div>
      <div (click)="section=2">
        <div class="section-selected" *ngIf="section==2"></div>
        <div class="section-unselected" *ngIf="section==1"></div>
        <p class="section-name" role="button" [class]="section==2 ? 'section-name-selected' : 'section-name'">Cálculos
          de abonado</p>
      </div>
    </div>


    <div *ngIf="interpretation && section===1" class="pb-5">
      <div *ngIf="interpretation.length===0" class="no-data">
        <span>No existe análisis en este año</span>
      </div>
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
          *ngFor="let item of interpretation; let i = index" [value]="i">

          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="section-name2">{{getSectionInter(i)}}</span>
            </mat-panel-title>

          </mat-expansion-panel-header>
          <div class="entry" *ngFor="let subitem of convertToArray(item)">
            <div class="first-line">
              <span class="param">{{subitem.parametro}}</span>
              <span *ngIf="!subitem.clasificacion" class="res">{{subitem.resultado}}</span>

            </div>
            <div class="second-line">
              <span class="clas"><span class="spanWeight" >Clasificación:</span> {{subitem.clasificacion}}</span>
              <span *ngIf="subitem.clasificacion" class="res">{{subitem.resultado}}</span>
            </div>
            <div *ngIf="subitem.valoracion" class="val">
              <span><span class="spanWeight">Valoración:</span> {{subitem.valoracion}}</span>
            </div>
            <div *ngIf="subitem.recomendacion" class="rec">
              <span><span class="spanWeight">Recomendación:</span> {{subitem.recomendacion}}</span>
            </div>
            <div *ngIf="subitem.prop" class="second-line">
              <span class="prop" *ngIf="subitem.prop=='ccc'">Capacidad de cambio catiónico</span>
              <span class="prop" *ngIf="subitem.prop=='sumacationes'">Suma cationes</span>
              <span class="res">{{subitem.value}}</span>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>

    </div>

    <div *ngIf="calcFert && section===2" class="pb-5">
      <div *ngIf="calcFert.length===0" class="no-data">
        <span>No existe el cálculo</span>
        <span class="procesando" *ngIf="analysis.estadopago==='Procesando'">Se está procesando el pago</span>
<!-- 
        <div class="comprado" *ngIf="!paid && interpretation.length!==0 && analysis.estadopago!=='Procesando'">
          <button class="button" expand="block" fill="clear" (click)="goPay()">
            Comprar análisis completo
          </button>
        </div> -->
      </div>
      <mat-accordion>


        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
          *ngFor="let item of calcFert; let e = index" [value]="e">

          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="section-name2">{{getSectionCalc(e)}}</span>
            </mat-panel-title>

          </mat-expansion-panel-header>

          <div *ngIf="e===0">
            <div class="entry">
              <div class="line">
                <div>
                  <span class="clas"><span >Capa arable:</span> {{item.capaarable}}</span>
                </div>
                <div>
                  <span class="clas"><span >Densidad(Tm/ha):</span> {{item.densidad}}</span>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="e===1">
            <div class="entry" *ngFor="let subitem of convertToArray(item)">
              <div class="text-title"><span>{{subitem.valoracion}}</span></div>
              <div class="display entry1">
                <div class="elem-div"><span class="elem">P: </span><span
                    *ngIf="subitem.fosforo">{{subitem.fosforo}}</span><span *ngIf="!subitem.fosforo">0</span></div>
                <div class="elem-div"><span>N: </span><span *ngIf="subitem.nitrogeno">{{subitem.nitrogeno}}</span><span
                    *ngIf="!subitem.nitrogeno">0</span></div>
                <div class="elem-div"><span>K: </span><span *ngIf="subitem.potasio">{{subitem.potasio}}</span><span
                    *ngIf="!subitem.potasio">0</span></div>
              </div>
            </div>
          </div>

          <div *ngIf="e===2">
            <div class="entry" *ngFor="let subitem of convertToArray(item); let si = index">
              <div class="medium" >
                <span *ngIf="si===0">Aluminio</span>
                <span *ngIf="si===1">Calcio</span>
                <span *ngIf="si===2">Hierro</span>
                <span *ngIf="si===3">Magnesio</span>
                <span *ngIf="si===4">Sodio</span>
              </div>
              <div *ngFor="let subsubitem of convertToArray(subitem); let ssi = index"
                class="display m12">
                <div class="mw">
                  <span
                    class="fontType fontTam">{{subsubitem.texto}}</span>
                </div>
                <div class="medium fontTam"><span
                    *ngIf="subsubitem.valor">{{subsubitem.valor}}</span><span *ngIf="!subsubitem.valor">0</span></div>
              </div>
            </div>
          </div>

          <div *ngIf="e===3">
            <div class="entry" *ngFor="let subitem of convertToArray(item); let si = index">
              <div class="medium" >
                <span *ngIf="si===0">Cobre</span>
                <span *ngIf="si===1">Hierro</span>
                <span *ngIf="si===2">Manganeso</span>
                <span *ngIf="si===3">Sulfato</span>
                <span *ngIf="si===4">Zinc</span>
              </div>
              <div *ngFor="let subsubitem of convertToArray(subitem); let ssi = index"
                class="display m12" >
                <div class="mw">
                  <span
                    class="fontType fontTam">{{subsubitem.texto}}</span>
                </div>
                <div class="medium fontTam"><span
                    *ngIf="subsubitem.valor">{{subsubitem.valor}}</span><span *ngIf="!subsubitem.valor">0</span></div>
              </div>
            </div>
          </div>

          <div *ngIf="e===4">
            <div class="entry" *ngFor="let subitem of convertToArray(item[0]); let si = index">
              <div *ngFor="let subsubitem of convertToArray(subitem); let ssi = index">
                <div class="display">
                  <div>
                    <span>{{subsubitem.nombre}}</span>
                  </div>
                  <div>
                    <span>{{subsubitem.precio}}€</span>
                  </div>
                </div>
                <div>
                  <p *ngIf="subsubitem.ca">Ca: {{subsubitem.ca}}</p>
                  <p *ngIf="subsubitem.k2o">K2O: {{subsubitem.k2o}}</p>
                  <p *ngIf="subsubitem.mg">Mg: {{subsubitem.mg}}</p>
                  <p *ngIf="subsubitem.n">N: {{subsubitem.n}}</p>
                  <p *ngIf="subsubitem.p2o5">P2O5: {{subsubitem.p2o5}}</p>
                  <p *ngIf="subsubitem.s">S: {{subsubitem.s}}</p>
                </div>
              </div>
            </div>

          </div>

          <div *ngIf="e===5 || i===6">
            <div class="entry" *ngFor="let subitem of convertToArray(item); let si = index">
              <div>
                <span *ngIf="si===0">Sementera</span>
                <span *ngIf="si===1">Cobertera</span>
              </div>
              <div>
                <div class="display" >
                  <div class="elem-div"><span *ngIf="subitem.ca">Ca: {{subitem.ca.valor}}</span></div>
                  <div class="elem-div"><span *ngIf="subitem.k2o">K2O: {{subitem.k2o.valor}}</span></div>
                  <div class="elem-div"><span *ngIf="subitem.mg">Mg: {{subitem.mg.valor}}</span></div>
                </div>
                <div class="display" >
                  <div class="elem-div"><span *ngIf="subitem.n">N: {{subitem.n.valor}}</span></div>
                  <div class="elem-div"><span *ngIf="subitem.p2o5">P2O5: {{subitem.p2o5.valor}}</span></div>
                  <div class="elem-div"><span *ngIf="subitem.s">S: {{subitem.s.valor}}</span></div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="e===7">
            <div class="entry" *ngFor="let subitem of convertToArray(item[0]); let si = index">
              <div *ngFor="let subsubitem of convertToArray(subitem); let ssi = index">
                <div class="medium" >
                  <span>{{subsubitem[0].nombre}}</span>
                </div>
                <div>
                  <div class="display" >
                    <div class="elem-div"><span *ngIf="subsubitem[0].ca">Ca: {{subsubitem[0].ca}}</span><span
                        *ngIf="!subsubitem[0].ca">Ca: 0</span></div>
                    <div class="elem-div"><span *ngIf="subsubitem[0].k2o">K2O: {{subsubitem[0].k2o}}</span><span
                        *ngIf="!subsubitem[0].k2o">K2O: 0</span></div>
                    <div class="elem-div"><span *ngIf="subsubitem[0].mg">Mg: {{subsubitem[0].mg}}</span><span
                        *ngIf="!subsubitem[0].mg">Mg: 0</span></div>
                  </div>
                  <div class="display" >
                    <div class="elem-div"><span *ngIf="subsubitem[0].n">N: {{subsubitem[0].n}}</span><span
                        *ngIf="!subsubitem[0].n">N: 0</span></div>
                    <div class="elem-div"><span *ngIf="subsubitem[0].p2o5">P2O5: {{subsubitem[0].p2o5}}</span><span
                        *ngIf="!subsubitem[0].p2o5">P2O5: 0</span></div>
                    <div class="elem-div"><span *ngIf="subsubitem[0].s">S: {{subsubitem[0].s}}</span><span
                        *ngIf="!subsubitem[0].s">S: 0</span></div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div *ngIf="e===8">
            <div class="entry" *ngFor="let subitem of convertToArray(item[0]); let si = index">
              <div *ngFor="let subsubitem of convertToArray(subitem); let ssi = index">

                <div class="medium" >
                  <span>{{subsubitem[0].nombre}}</span>
                </div>
                <div>
                  <p>Kg: {{subsubitem[0].kg}} kg</p>
                  <p>Precio/Kilo: {{subsubitem[0].precioekg}} €</p>
                  <p>Coste: {{subsubitem[0].coste}} €</p>
                </div>
              </div>
            </div>
          </div>

          <div [hidden]="!(e===9 || e===10 || e===11)" >
            <div class="entry" *ngFor="let subitem of convertToArray(item); let si = index">
              <div class="medium" >
                <span *ngIf="si===0">Suma total</span>
                <span *ngIf="si===1">Sementera</span>
                <span *ngIf="si===2">Cobertera</span>
              </div>
              <div *ngIf="e===9">
                <p>Kg: {{subitem.kg}} kg</p>
                <p>Euros: {{subitem.euros}} €</p>
              </div>
              <div *ngIf="e===10">
                <p>Kg: {{subitem.kg}} kg/ha</p>
                <p>Euros: {{subitem.euros}} €/ha</p>
              </div>
              <div *ngIf="e===11">
                <p>Kg: {{subitem.kg}} kg/fanega</p>
                <p>Euros: {{subitem.euros}} €/fanega</p>
              </div>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>




    </div>
  </ng-container>

</div>