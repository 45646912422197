<div class="container p-sm-auto p-0">


        <div class="center">
          <fa-icon [icon]="['far','circle-xmark']"></fa-icon>
          <span>
            Se ha producido un error con el pago
          </span>

        <div class="btn-save">

            <button (click)="back()" expand="block" fill="clear" shape="round">
                Volver
              </button>
          </div>
  
      
</div>