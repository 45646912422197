import { Component } from '@angular/core';

@Component({
  selector: 'app-privacidad',
  templateUrl: './privacidad.component.html',
  styleUrls: ['./privacidad.component.scss']
})
export class PrivacidadComponent {
  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo(0, 0);
      }, 100);
  }

}
