

<div class="bg-white">
  <div class="download-app">
    <div class="container container-download-app" id="download-app">
      <fa-icon (click)="cerrar()"  class="icon-download-app" [icon]="faClose" style="font-size: 30px; display: flex; align-items: flex-end; justify-content: flex-end; padding-bottom: 50px ;"></fa-icon>
      <div class="row">
        <div class="col-md-6 order-2 order-md-1">
          <div class="app-img">
            <img class="" src="/assets/images/app_image.png" alt="...">
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2">
          <div class="app-title">Para realizar un análisis descarga nuestra app</div>
   
          <div class="app-image-download">
            <a href="https://play.google.com/store/apps/details?id=app.calahorra.app" class="link-app-store">
              <img class="img1" src="/assets/images/google-play-badge.svg" alt="...">
            </a>
            <a href="https://apps.apple.com/app/ayuntamiento-de-calahorra/id1615548075" class="link-app-store" target="_blank">
              <img class="img2" src="/assets/images/app-store.svg" alt="...">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>