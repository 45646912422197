import { Component, HostListener, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialog } from '../../shared/login-dialog/LoginDialog';
import { Router, ActivatedRoute } from '@angular/router';

/** ICONS */
import {
  faCircleUser, faHourglassClock, faLockKeyhole, faChevronDown, faCircleCheck, faCalendarLinesPen, faMagnifyingGlassChart, faPiggyBank, faPenRuler,
  faLightbulbOn, faChartLine, faChevronUp
} from "@fortawesome/pro-light-svg-icons";
import {faHeart} from "@fortawesome/pro-solid-svg-icons";
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Platform } from '@angular/cdk/platform';
import { ChangePasswordComponent } from '../../shared/change-password/change-password.component';
import { NotificacionDialogComponent } from 'src/app/shared/notificacion-dialog/notificacion-dialog.component';
import { DownloadAppComponent } from 'src/app/shared/download-app/download-app.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public toggleNavbar: boolean = true;
  public isFixed: boolean = false;
  login: boolean = false
  faCircleUser = faCircleUser;
  faHourglassClock = faHourglassClock;
  faLockKeyhole = faLockKeyhole;
  faChevronDown = faChevronDown;
  faCircleCheck = faCircleCheck;
  faCalendarLinesPen = faCalendarLinesPen;
  faMagnifyingGlassChart = faMagnifyingGlassChart;
  faPiggyBank = faPiggyBank;
  faPenRuler = faPenRuler;
  faLightbulbOn = faLightbulbOn;
  faChartLine = faChartLine;
  faChevronUp = faChevronUp;
  faHeart = faHeart;
  internor: boolean = false;
  legal: boolean = false;
  contacto: boolean = false;

  constructor(private router: Router, public dialog: MatDialog, private route: ActivatedRoute, private auth: AuthenticationService,
    private platform: Platform, private meta: Meta, private title: Title) {
    this.title.setTitle('Fertismart - La agricultura del futuro.');
    if (this.meta.getTag('name=description')) {
      this.meta.updateTag({ name: 'description', content: 'Fertismart es la herramienta digital para que puedas lograr el rendimiento más eficaz y sustentable de la forma más simple.' });
    } else {
      this.meta.addTag({ name: 'description', content: 'Fertismart es la herramienta digital para que puedas lograr el rendimiento más eficaz y sustentable de la forma más simple.' });
    }

    try {
      this.auth.getUsersMe().subscribe((response: any) => {
        if (response.status) {
          this.login = false;
          localStorage.removeItem('jwt_token');
        } else {
          this.login = true;
        }
      }, error => {
        this.login = false;
        localStorage.removeItem('jwt_token');
      });
    } catch (error) {
      localStorage.removeItem('jwt_token');
      this.login = false;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['verification']) {
      }
      if (params['email'] && params['token'] && !params['isr']) {
        this.auth.validateEmail(params['email'], params['token']).subscribe(resp => {
          const info = JSON.parse(resp);
          this.notificacionDialog('0.5s', '0.5s', info.message, 1, info.title);
          //quitar parametros de la ruta
          this.router.navigate([], {
            queryParams: {
              email: null,
              token: null,
              verification: null
            },
            queryParamsHandling: 'merge'
          });
        });
      } else if (params['email'] && params['token'] && params['isr']) {
        //cambiar contraseña
        const dialogRef = this.dialog.open(ChangePasswordComponent, {
          width: '400px',
          data: { email: params['email'], token: params['token'] }
        }).afterClosed().subscribe(result => {
          this.router.navigate([], {
            queryParams: {
              email: null,
              token: null,
              isr: null,
              verification: null
            },
            queryParamsHandling: 'merge'
          });
        });
      }
    });
  }

  openDialog() {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.downloadApp();
    }
    else {
      // const dialogRef = this.dialog.open(LoginDialog, {
      //   width: '400px',
      // });

      // dialogRef.afterClosed().subscribe(result => {

      // });

      // location.href = "./home#download-app";
      this.notificacionDialog('0.2s', '0.2s', 'Para realizar un análisis, por favor descarga nuestra aplicación.', 1, 'Advertencia');

    }
  }

  goToProfile() {
    this.router.navigateByUrl('/perfil');
  }

  logout() {
    localStorage.removeItem('jwt_token');
    this.router.navigate(['/']);
  }

  notificacionDialog(enterAnimationDuration: string, exitAnimationDuration: string, mensaje, botones, title): void {
    this.dialog.open(NotificacionDialogComponent, {
      data: { msg: mensaje, botones: botones, title: title },
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }
  downloadApp() {
    const dialogRef = this.dialog.open(DownloadAppComponent, {
      width: '100vw',
      height: '100vh',

    });
  }

  goTo(value) {
    location.href = value;
  }

}
