import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  sendEmail(data:any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/contacto`;
    return this.http.post<any>(url, data,formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return data;
      }), catchError((err) => {
        return of(false);
      })
    );
  }
}
