import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { AppSettings } from '../app-settings';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EntitysService {

  constructor(private common: CommonService, private http: HttpClient) { }

  getAbonosProductos() {
    const url = `${ AppSettings.API_URI }/api/abonos`;
    return this.common.getDataHydra(url);
  }

  getCrops() {
    const url = `${ AppSettings.API_URI }/api/cultivos`;
    return this.common.getDataHydra(url);
  }

  getCropById(id: any) {
    return this.http.get<any>(AppSettings.API_URI + '/api/cultivos/'+id).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    );
  }

}
