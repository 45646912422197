<div id="header" class="d-flex align-items-center justify-content-between">
    <div class="logo">
        <!-- <a (click)="backFront()"><img src="../../../../assets/images/Logo_Calahorra_Inscripciones_Reservas.svg" alt="Logo Calahorra" class="img-fluid"></a> -->
    </div>
    <div class="menu-admin" *ngIf="currentUser" mat-button [matMenuTriggerFor]="menu" matRipple>
        <div class="d-flex align-items-center btn-panel">
            <i mat-card-avatar class='fas fa-user-alt icon-avatar' *ngIf="!imageSrc"></i>
            <img mat-card-avatar *ngIf="imageSrc" class="perfil-card-image" [src]="imageSrc" />
            <span class="name-user-admin" *ngIf="currentUser">{{ currentUser.nombre }} {{ currentUser.apellidos }}</span>
            <span class="icon-arrow-down"><i class="fas fa-sort-down"></i></span>
        </div>
        <mat-menu #menu="matMenu" class="admin-panel">
            <button [routerLink]="['/']" mat-menu-item><i class="fal fa-home-alt"></i> Inicio</button>
            <button [routerLink]="['/profile']" mat-menu-item><i class="fal fa-user-alt"></i> Mi Perfil</button>
            <button [routerLink]="['/admin']" *ngIf="isAdmin" mat-menu-item><i class="fal fa-key"></i> Panel Administrador</button>
            <button (click)="logout()" mat-menu-item><i class="fal fa-sign-out-alt"></i> Cerrar sesión</button>
        </mat-menu>
    </div>
</div>
