<app-menu [home]="false"></app-menu>
<div class="container padding">
    <h3 class="title-terms">Politica de privacidad</h3>
    <p>La presente Política de Privacidad establece el tratamiento de los datos personales recopilados a través del sitio web ("Sitio web") operado por Internor Ingenieros ("Nosotros" o "nuestro"). Nos comprometemos a proteger y respetar su privacidad y a cumplir con la legislación aplicable en materia de protección de datos.</p>
    <p class="sub-title-terms">Información que recopilamos</p>
    <p>Podemos recopilar y procesar los siguientes datos personales cuando usted utiliza nuestro Sitio web:</p>
    <p>Información que usted proporciona voluntariamente al completar formularios en el Sitio web, como nombre, dirección de correo electrónico, número de teléfono u otra información de contacto.</p>
    <p>Información que se genera automáticamente durante su visita al Sitio web, como datos de acceso, direcciones IP, tipo de navegador, información sobre su dispositivo y patrones de uso.</p>
    <p class="sub-title-terms">Uso de la información recopilada</p>
    <p>Utilizamos la información recopilada para los siguientes propósitos:</p>
    <p>Proporcionar y mejorar nuestros servicios y funcionalidades del Sitio web.</p>
    <p>Responder a sus consultas, solicitudes o preguntas.</p>
    <p>Enviar comunicaciones relacionadas con nuestros servicios, como actualizaciones, notificaciones técnicas y cambios en nuestra Política de Privacidad.</p>
    <p>Cumplir con nuestras obligaciones legales y resolver cualquier disputa.</p>
    <p>Comunicación de datos personales</p>
    <p class="sub-title-terms">Podemos compartir sus datos personales con terceros en las siguientes circunstancias:</p>
    <p>Con proveedores de servicios que nos ayudan en la operación y mantenimiento del Sitio web.</p>
    <p>Con autoridades gubernamentales, organismos reguladores u otras partes cuando estemos legalmente obligados a hacerlo o para proteger nuestros derechos legales.</p>
    <p>Con su consentimiento expreso o conforme a sus instrucciones.</p>
    <p>Cookies y tecnologías similares</p>
    <p>Utilizamos cookies y tecnologías similares para recopilar información sobre su uso del Sitio web. Puede obtener más información sobre esto en nuestra Política de Cookies.</p>
    <p class="sub-title-terms">Seguridad de los datos</p>
    <p>Implementamos medidas de seguridad adecuadas para proteger sus datos personales contra el acceso no autorizado, la divulgación, la alteración o la destrucción.</p>
    <p class="sub-title-terms">Sus derechos</p>
    <p>Usted tiene derechos sobre sus datos personales y puede ejercerlos en cualquier momento, incluyendo el derecho de acceso, rectificación, supresión, limitación, oposición y portabilidad de datos. Puede ponerse en contacto con nosotros utilizando la información de contacto proporcionada al final de esta Política de Privacidad.</p>
    <p class="sub-title-terms">Retención de datos</p>
    <p>Mantendremos sus datos personales durante el tiempo necesario para cumplir con los fines para los que fueron recopilados, así como para cumplir con nuestras obligaciones legales y resolver disputas.</p>
    <p class="sub-title-terms">Enlaces a sitios web de terceros</p>
    <p>Nuestro Sitio web puede contener enlaces a sitios web de terceros. No nos hacemos responsables de las prácticas de privacidad o del contenido de dichos sitios web. Le recomendamos leer las políticas de privacidad de esos sitios web antes de proporcionarles cualquier información personal.</p>
    <p class="sub-title-terms">Cambios en nuestra Política de Privacidad</p>
    <p>Nos reservamos el derecho de modificar o actualizar esta Política de Privacidad en cualquier momento. Le recomendamos revisar periódicamente esta Política de Privacidad para estar al tanto de cualquier cambio. La fecha de la última actualización se indica al principio de esta Política de Privacidad.</p>
    <p class="sub-title-terms">Contacto</p>
    <p>Si tiene alguna pregunta, inquietud o solicitud relacionada con esta Política de Privacidad, puede ponerse en contacto con nosotros a través de oficina@internoringenieros.es.</p>
    <p>Internor Ingenieros - NIF 16542328S</p>
</div>

