
<div class="container p-sm-auto p-0 ">

    <div class="title-div">
      <div class="header">
        <fa-icon role="button" class="faiconBack"  [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
        <span class="title">Proveedores</span>
      </div>
    </div>
  
    <div class="selectProvider">
      <span >Selecciona el proveedor que más se ajuste a tus necesidades</span>
    </div>
  
    <div class="sections-div">
      <div (click)="section=1">
        <!-- <div class="section-selected" *ngIf="section==1"></div>
        <div class="section-unselected" *ngIf="section==2"></div> -->
        <p class="section-name" [class]="section==1 ? 'section-name-selected' : 'section-name'"><span *ngIf="selectedS.length!==0">({{selectedS.length}})</span> Sementera</p>
      </div>
      <div (click)="section=2">
        <!-- <div class="section-selected" *ngIf="section==2"></div>
        <div class="section-unselected" *ngIf="section==1"></div> -->
        <p class="section-name" [class]="section==2 ? 'section-name-selected' : 'section-name'"><span *ngIf="selectedC.length!==0">({{selectedC.length}})</span> Cobertera</p>
      </div>
    </div>
  
    <!-- <div style="border-bottom: 1px solid var(--color-grey-3); padding-bottom: 15px;" [style]="openSelect ? 'margin-top: -106px;':''"></div> -->
    <div *ngIf="section===1">
      <div [class]="getClassAbonoS(i,item)" *ngFor="let item of abonos; let i = index" (click)="selectOptionS(i)">
        <!-- <div style="margin-bottom: 10px;">
          <fa-icon style="color: var(--color-green-1);" [icon]="['fas','star']"></fa-icon>
        </div> -->
        <div class="container1" >
          <div class="displaySect1" >
            <span>{{item.nombre}}</span>
            <span  class="grey-text">{{item.casacomercial}}</span>

          </div>
          <div class="d-flex flex-column w-50" >
            <div class="d-flex justify-content-end">
              <div class="element-div">
                <span class="grey-text">N</span>
                <span class="number"><span *ngIf="item.n">{{item.n}}</span><span *ngIf="!item.n">0</span></span>
              </div>
              <div class="element-div">
                <span class="grey-text">P</span>
                <span class="number"><span *ngIf="item.p2o5">{{item.p2o5}}</span><span *ngIf="!item.p2o5">0</span></span>
              </div>
              <div class="element-div">
                <span class="grey-text">K</span>
                <span class="number"><span *ngIf="item.k2o">{{item.k2o}}</span><span *ngIf="!item.k2o">0</span></span>
              </div>
            </div>
            <div class="d-flex justify-content-end mt10" >
              <div class="element-div">
                <span class="grey-text">Ca</span>
                <span class="number"><span *ngIf="item.ca">{{item.ca}}</span><span *ngIf="!item.ca">0</span></span>
              </div>
              <div class="element-div">
                <span class="grey-text">Mg</span>
                <span class="number"><span *ngIf="item.mg">{{item.mg}}</span><span *ngIf="!item.mg">0</span></span>
              </div>
              <div class="element-div">
                <span class="grey-text">S</span>
                <span class="number"><span *ngIf="item.s">{{item.s}}</span><span *ngIf="!item.s">0</span></span>
              </div>
            </div>
            <div class="d-flex justify-content-end mt13 fs16 ffarchivo" >
              <span >{{item.preciotm}} €-Tm</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
    <div  *ngIf="section===2">
      <div [class]="getClassAbonoC(i,item)" *ngFor="let item of abonos; let i = index" (click)="selectOptionC(i)">
        <!-- <div style="margin-bottom: 10px;">
          <fa-icon style="color: var(--color-green-1);" [icon]="['fas','star']"></fa-icon>
        </div> -->
        <div class="d-flex justify-content-between">
          <div  class="d-flex flex-column justify-content-between w-50">
            <span class="fs18 ffArchivoExtraBold" >{{item.nombre}}</span>
            <span class="mt7 mw100 grey-text">{{item.casacomercial}}</span>
            <!-- <div style=" margin-top: 10px;">
              <fa-icon [icon]="['far','droplet']"></fa-icon>
              <span style="font-family: Archivo Regular; margin-left: 8px;">1.023,93 Kg/HA</span>
            </div> -->
          </div>
          <div class="d-flex flex-column w-50">
            <div class="d-flex justify-content-end" >
              <div class="element-div">
                <span class="grey-text">N</span>
                <span class="number"><span *ngIf="item.n">{{item.n}}</span><span *ngIf="!item.n">0</span></span>
              </div>
              <div class="element-div">
                <span class="grey-text">P</span>
                <span class="number"><span *ngIf="item.p2o5">{{item.p2o5}}</span><span *ngIf="!item.p2o5">0</span></span>
              </div>
              <div class="element-div">
                <span class="grey-text">K</span>
                <span class="number"><span *ngIf="item.k2o">{{item.k2o}}</span><span *ngIf="!item.k2o">0</span></span>
              </div>
            </div>
            <div class="d-flex justify-content-end mt10" >
              <div class="element-div">
                <span class="grey-text">Ca</span>
                <span class="number"><span *ngIf="item.ca">{{item.ca}}</span><span *ngIf="!item.ca">0</span></span>
              </div>
              <div class="element-div">
                <span class="grey-text">Mg</span>
                <span class="number"><span *ngIf="item.mg">{{item.mg}}</span><span *ngIf="!item.mg">0</span></span>
              </div>
              <div class="element-div">
                <span class="grey-text">S</span>
                <span class="number"><span *ngIf="item.s">{{item.s}}</span><span *ngIf="!item.s">0</span></span>
              </div>
            </div>
            <div class="d-flex justify-content-end fs16 ffArchivo mt13" >
              <span >{{item.preciotm}} €-Tm</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  
    <div class="mt110" >
  
    </div>
  
    <div class="button-div">
      <button class="button"  [disabled]="btnAceptar" expand="block" fill="clear" (click)="save()">
        Aceptar
      </button>
    </div>
  
    <!-- <div class="entry">
      <div style="display: flex; flex-direction: column; justify-content: space-between;">
        <span style="font-size: 18px; font-family: Archivo ExtraBold;">Tiomag S</span>
        <span style="margin-top: 7px;" class="grey-text">Fitoproveedoreslux</span>
        <div style=" margin-top: 10px;">
          <fa-icon [icon]="['far','droplet']"></fa-icon>
          <span style="font-family: Archivo Regular; margin-left: 8px;">1.023,93 Kg/HA</span>
        </div>
      </div>
  
    </div> -->
  
</div>