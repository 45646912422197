import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ContactoComponent } from './contacto/contacto.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { EmailDialogComponent } from './email-dialog/email-dialog.component';
import { HeaderAdminComponent } from './header-admin/header-admin.component';
import { LoginDialog } from './login-dialog/LoginDialog';
import { NotificacionDialogComponent } from './notificacion-dialog/notificacion-dialog.component';
import { ParcelResumeComponent } from './parcel-resume/parcel-resume.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '../material.module';
import { EditTableComponent } from './edit-table/edit-table.component';
import { MenuComponent } from './menu/menu.component';
import { ColNamePipe } from '../pipes/col-name.pipe';
import { PipeModule } from '../pipes/pipe.module';



@NgModule({
  declarations: [
    ChangePasswordComponent,
    ContactoComponent,
    DownloadAppComponent,
    EmailDialogComponent,
    HeaderAdminComponent,
    LoginDialog,
    NotificacionDialogComponent,
    ParcelResumeComponent,
    SideBarComponent,
    EditTableComponent,
    MenuComponent,
 

  ],
  imports: [
    CommonModule,
    BrowserModule,
    // AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgbCollapseModule,
    MaterialModule,
    FontAwesomeModule,
    PipeModule
  ],
  exports: [
    ChangePasswordComponent,
    ContactoComponent,
    DownloadAppComponent,
    EmailDialogComponent,
    HeaderAdminComponent,
    LoginDialog,
    NotificacionDialogComponent,
    ParcelResumeComponent,
    SideBarComponent,
    EditTableComponent,
    MenuComponent
  ],
})
export class SharedModule { }
