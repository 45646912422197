<div class="container p-sm-auto p-0">
<ng-container  *ngIf="parcel">
    <div class="row">
      <div class="col-3 header" >
        <div class="parcel-polygon">
          <img src="../../../assets/provisional/parcela.png"  class="img-fluid"/>
        </div>
      </div>
      <div class="col-9">
        <div class="row">
          <div class="col-12">
            <p>{{parcel.nombre |titlecase}}</p>
          </div>
          <div class="col-5">
            <div class="display-inline " >
              <fa-icon class="fa" [icon]="iconos.faBarcode"></fa-icon>
              <span class="ellipsis-resp"> {{parcel.provsigpac}}.{{parcel.munsigpac}}.{{parcel.parcela}}.{{parcel.poligono}}</span>
            </div>
          </div>
          <div class="col-7">
            <div *ngIf="parcel.analisis.length>0 && analysis && analysis.interpretagen" class="display-inline w-100 align-items-end" >
              <fa-icon class="icon-available d-flex" [icon]="iconos.faCircle"></fa-icon>
              <span class="ellipsis-resp">Datos disponibles</span>
            </div>
            <div *ngIf="parcel.analisis.length>0 && analysis && !analysis.interpretagen" class="display-inline w-100 align-items-end" >
              <fa-icon class="icon-loading"  [icon]="iconos.faCircle"></fa-icon>
              <span class="ellipsis-resp">Cargando datos</span>
            </div>
            <div *ngIf="parcel.analisis.length===0 && !analysis" class="display-inline w-100 align-items-end" >
              <fa-icon class="icon-unavailable d-flex"  [icon]="iconos.faCircle"></fa-icon>
              <span class="ellipsis-resp">Sin datos</span>
            </div>
          </div>
          <div class="col-5">
            <div class="display-inline " >
              <fa-icon class="fa" [icon]="iconos.faDrawSquare"></fa-icon>
              <span class="ellipsis-resp"> {{parcel.supersiembra}} HA</span>
            </div>
          </div>
          <div class="col-7">
  
            <div *ngIf="parcel.secano" class="display-inline " >
              <fa-icon class="fa" [icon]="iconos.faCloudDrizzle"></fa-icon>
              <span class="ellipsis-resp">Secano</span>
            </div>
  
            <div *ngIf="parcel.regadio" class="display-inline " >
              <fa-icon class="fa" [icon]="iconos.faSprinkler"></fa-icon>
              <span class="ellipsis-resp">Regadío</span>
            </div>
  
          </div>
          <div class="col-7">
            <div class="display-inline " >
              <fa-icon class="fa" [icon]="iconos.faMapLocation"></fa-icon>
              <span class="ellipsis-resp"> {{parcel.provincia}}</span>
            </div>
          </div>
          <div class="col-5">
            <div class="display-inline" >
            </div>
          </div>
        </div >
      </div>
    </div >
</ng-container >
</div>
