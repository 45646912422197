import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class SigpacService {

  constructor(private http: HttpClient) { }


  getSigPacData(recinto, municipio, agregado, codzona, poligono, parcela, provincia) {

    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/api/proxy`;

    let payload = {
      url: 'https://sigpac.mapama.gob.es/fega/ServiciosVisorSigpac/query/recinfo/' + provincia + '/' + municipio + '/' + agregado + '/' + codzona + '/' + poligono + '/' + parcela + '/' + recinto + '.json'
    }
    return this.http.post<any>(url, payload, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );

  }

  // getSigPac(lat,lng) {
  //   const globalMercator = require('global-mercator')
  //   const tiles = globalMercator.lngLatToTile([ lng,lat], 15);
  //   console.log(tiles);
  //   return this.http.get<any>('https://sigpac.mapama.gob.es/vectorsdg/vector/parcela@3857/15.'+tiles[0]+'.'+tiles[1]+'.geojson', {}).pipe(
  //       map(data => {
  //           return data;
  //       }, error => {
  //           return false;
  //       })
  //   );
  // }

  getSigPac(lng, lat: any) {

    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${AppSettings.API_URI}/api/getSigpac`;
    const globalMercator = require('global-mercator')

    const tiles = globalMercator.lngLatToTile([lng, lat], 15);
    let params = {
      lng: tiles[0],
      lat: tiles[1]
    }
    return this.http.post<any>(url, params, formHeaders).pipe(
      map(data => {
        if (data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }
}
