<div class="fondo">


  <div class="d-flex justify-content-between">

    <button class="buttonCategory" type="button" mat-button (click)="drawer.toggle();">
      <mat-icon>menu</mat-icon>
      Menú
    </button>
    <div class="right-menu">

      <button class="buttonCategory" type="button" mat-button [routerLink]="['/']">
        <mat-icon>home</mat-icon> Home
      </button>
      <button class="buttonCategory" type="button" mat-button (click)="logout();">
        <mat-icon>logout</mat-icon> Cerrar Sesión
      </button>

      <button class="buttonCategory" type="button" mat-button [routerLink]="['/perfil']">
        <fa-icon [icon]="faCircleUser" class="icon-login-header"></fa-icon> Perfil
      </button>
    </div>
  </div>

  <mat-drawer-container class="example-container" autosize>

    <mat-drawer #drawer opened="" class="example-sidenav" mode="side">


      <mat-list>
        <div mat-subheader class="menu-section-title">Análisis</div>
        <mat-list-item class="item font" role="button" (click)="analysisView()" [class.activo]="nombreTabla=='analisis'">Listado de análisis</mat-list-item>

        <div mat-subheader class="menu-section-title">Usuarios</div>
        <mat-list-item class="item font" role="button" (click)="usersView()" [class.activo]="nombreTabla=='usuarios'">Listado de usuarios</mat-list-item>

        <div mat-subheader class="menu-section-title">Elementos</div>
        <mat-list-item class="item font" role="button" *ngFor="let elements of menu" (click)="elementChange(elements)"
          [class.activo]="type==elements.name">{{elements.name}}</mat-list-item>
      </mat-list>
    </mat-drawer>
    <mat-drawer-content>
      <div class="example-sidenav-content">
        <div class="centro-card">
          <mat-card class="mt-1">
            <mat-card-content class="centro-card-description" class="content-table">
              <div w-100 class="d-flex align-items-center justify-content-between title-table">
                <h4>{{type}}</h4>
                <button class="mat-stroked-button blue-icon mb-2"
                  *ngIf="roleAdmin == 'superAdmin' || roleAdmin == 'rdAdmin'" [routerLink]="['/admin/centros/new']"><i
                    class="far fa-plus"></i> Nuevo
                  centro</button>
              </div>
              <div class="container-table mat-elevation-z8">

                <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
                  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                  <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">

                  </div>
                </div>

                <table *ngIf="nombreTabla!=='analisis' && nombreTabla!=='usuarios'" mat-table [dataSource]="dataSource"
                  class="mat-elevation-z8" matSort matSortActive="Id" matSortDisableClear matSortDirection="desc">



                  <ng-container *ngFor="let disCol of displayedColumns" matColumnDef="{{disCol}}">
                    <th mat-header-cell *matHeaderCellDef disableClear> {{disCol |colName |titlecase}}
                    </th>
                    <ng-container *ngIf="disCol=='recomendacion' || disCol=='interpretacion';else td">
                      <td mat-cell *matCellDef="let element" class="">
                        <div class="d-flex justify-content-between align-items-center">
                          <div [id]="disCol+element.id" class="editable ">
                            {{element[disCol]}}
                          </div>
                          <div>
                            <mat-icon role="button"
                              (click)="openDialog(element.id,disCol,element[disCol])">edit</mat-icon>
                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <td #td mat-cell *matCellDef="let element"> {{element[disCol]}} </td>

                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <table *ngIf="nombreTabla==='analisis'" mat-table [dataSource]="dataSourceAnalysis"
                  class="mat-elevation-z8" matSort matSortActive="Id" matSortDisableClear matSortDirection="desc">


                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef disableClear> Nombre de usuario</th>
                    <td #td mat-cell *matCellDef="let element">{{element.usuario.nombre}}</td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef disableClear> Email</th>
                    <td #td mat-cell *matCellDef="let element">{{element.usuario.email}}</td>
                  </ng-container>

                  <ng-container matColumnDef="parcel">
                    <th mat-header-cell *matHeaderCellDef disableClear> Parcela</th>
                    <td #td mat-cell *matCellDef="let element">{{element.nombre}}</td>
                  </ng-container>

                  <ng-container matColumnDef="year">
                    <th mat-header-cell *matHeaderCellDef disableClear> Año del análisis</th>
                    <td #td mat-cell *matCellDef="let element">{{element.anoanalisis}}</td>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef disableClear> Fecha del análisis</th>
                    <td #td mat-cell *matCellDef="let element">{{element.fecha.date |date: 'dd/MM/yyyy'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="estadopago">
                    <th mat-header-cell *matHeaderCellDef disableClear> Estado pago</th>
                    <td #td mat-cell *matCellDef="let element">{{element.estadoPago||'Gratuito'}}</td>
                  </ng-container>
                  <!-- <ng-container matColumnDef="year">
                    <th mat-header-cell *matHeaderCellDef disableClear> Tipo análisis</th>
                    <td #td mat-cell *matCellDef="let element">{{element.anoanalisis}}</td>
                  </ng-container>

                  <ng-container matColumnDef="year">
                    <th mat-header-cell *matHeaderCellDef disableClear> Estado análisis</th>
                    <td #td mat-cell *matCellDef="let element">  {{ element.estadopago ? 'De pago' : 'Gratuito' }}</td>
                  </ng-container>

                  <ng-container matColumnDef="year">
                    <th mat-header-cell *matHeaderCellDef disableClear> Fecha análisis</th>
                    <td #td mat-cell *matCellDef="let element">{{element.fecha}}</td>
                  </ng-container> -->


                  <tr mat-header-row *matHeaderRowDef="displayedColumnsAnalysis"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsAnalysis;" class="table-row" (click)="goToAnalysis(row)"></tr>
                </table>
                <table *ngIf="nombreTabla==='usuarios'" mat-table [dataSource]="dataSourceUsers"
                  class="mat-elevation-z8" matSort matSortActive="Id" matSortDisableClear matSortDirection="desc">


                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef disableClear> Nombre</th>
                    <td #td mat-cell *matCellDef="let element" class="table-row" [cdkCopyToClipboard]="element.nombre">{{element.nombre}}</td>
                  </ng-container>

                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef disableClear> Email</th>
                    <td #td mat-cell *matCellDef="let element" class="table-row" [cdkCopyToClipboard]="element.email">{{element.email}}</td>
                  </ng-container>

                  <ng-container matColumnDef="nempresa">
                    <th mat-header-cell *matHeaderCellDef disableClear> Nombre de empresa </th>
                    <td #td mat-cell *matCellDef="let element" class="table-row" [cdkCopyToClipboard]="element.nempresa">{{element.nempresa}}</td>
                  </ng-container>

                  <ng-container matColumnDef="telephone">
                    <th mat-header-cell *matHeaderCellDef disableClear> Teléfono</th>
                    <td #td mat-cell *matCellDef="let element" class="table-row" [cdkCopyToClipboard]="element.telefono">{{element.telefono}}</td>
                  </ng-container>

                  <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef disableClear> Dirección</th>
                    <td #td mat-cell *matCellDef="let element" class="address-row table-row" [cdkCopyToClipboard]="buildAddressString(element)">{{buildAddressString(element)}}</td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumnsUsers"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsUsers;"></tr>
                </table>
                <mat-paginator id="paginator" [length]="resultsLength" [pageSize]="10"
                  [pageSizeOptions]="[5,10,20,25,50,100]" aria-label=""></mat-paginator>
              </div>

            </mat-card-content>
          </mat-card>
        </div>


        <!-- <button mat-raised-button (click)="drawer.toggle()">Toggle drawer</button> -->

      </div>

    </mat-drawer-content>
  </mat-drawer-container>

</div>
