<div class="container p-sm-auto p-0">

    <div class="title-div">
      <div class="header">
        <fa-icon role="button" class="faiconBack"  [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
        <span class="title">Crear parcela</span>
      </div>
      <!-- <fa-icon style="font-size: 24px; margin-right: 20px;" [icon]="['far','times']"></fa-icon> -->
    </div>
    <div class="parcel-draw-div">
      <img src="../../../assets/provisional/parcela.png" alt="">
    </div>
    <div class="inputs">
  
        <div class="section-title">
            <span>Datos de entrada</span>
          </div>
  
        <div class="input-div">
     
          <!-- <input matInput placeholder="Ex. Pizza" value="Sushi"> -->
          <input type="text" placeholder="Nombre parcela" [(ngModel)]="nombre" [class]=" nombre!== '' ? 'valid' : 'invalid'">
        </div>
     
    
      <div class="input-div">
        <input type="text" placeholder="Paraje" >
      </div>

      <div class="section-title">
        <span>Datos agrícolas</span>
      </div>
      <div class="input-div" [class]=" cultivo!== '' ? 'valid' : 'invalid'">
        <select class="myFormSelect" aria-label="Default select example" (change)="clickCrop($event)">
            <option value="" selected>Tipo de cultivo</option>
          <option *ngFor="let item of cultivos"  [value]="[item.id,item.cultivo]">{{item.cultivo |titlecase}}</option>
        </select>
      </div>
      
      <div class="input-div">
        <input type="text" placeholder="Referencia catastral" [(ngModel)]="refcatastral" >
      </div>
       
      <div class="input-div">
        <select class="myFormSelect" aria-label="Método de cultivo" (change)="clickMethod($event)" [class]=" metodo!== '' ? 'valid' : 'invalid'">
            <option value="" selected>Método de cultivo</option>
        <option [selected]="metodo=='Secano'"  value="Secano">Secano</option>
        <option  [selected]="metodo=='Regadío'"   value="Regadío">Regadío</option>
        </select>
      </div>
      
      <div class="input-div">
        <input type="number" placeholder="Superficie de siembra" [(ngModel)]="supersiembra" [class]=" supersiembra!== null ? 'valid' : 'invalid'">
      </div>
      <div class="input-div">
        <input type="number" placeholder="Densidad" [(ngModel)]="densidad" [class]=" densidad!== null ? 'valid' : 'invalid'">
      </div>
      <div class="input-div">
        <input type="number" placeholder="Capa arable" [(ngModel)]="capaarable" [class]=" capaarable!== null ? 'valid' : 'invalid'">
      </div>
      <div class="input-div">
        <input type="number" placeholder="Producción esperada" [(ngModel)]="prodesperada" [class]=" prodesperada!== null ? 'valid' : 'invalid'">
      </div>
  
      
      <div class="section-title">
        <span>Datos de localización</span>
      </div>

      <div class="input-div">
        <input type="text" placeholder="Nombre Localidad" [(ngModel)]="localidad">
      </div>
      <div class="input-div">
        <input type="text" placeholder="Nombre provincia" [(ngModel)]="provincia">
      </div>
      <div class="input-div">
        <input type="text" placeholder="Nombre municipio" [(ngModel)]="municipio">
      </div>
      <div class="input-div">
        <input type="number" placeholder="Provincia (Cód. SigPac)" [(ngModel)]="provsigpac" [class]=" provsigpac!== null ? 'valid' : 'invalid'">
      </div>
      <div class="input-div">
        <input type="number" placeholder="Municipio (Cód. SigPac)" [(ngModel)]="munsigpac" [class]=" munsigpac!== null ? 'valid' : 'invalid'">
      </div>
      <div class="input-div">
          <input type="number" placeholder="Polígono (Cód. SigPac)" [(ngModel)]="poligono" [class]=" poligono!== null ? 'valid' : 'invalid'">
        </div>
        <div class="input-div">
          <input type="number" placeholder="Parcela (Cód. SigPac)" [(ngModel)]="parcela" [class]=" parcela!== null ? 'valid' : 'invalid'">
        </div>
      
   
    <div class="input-div">
        <input type="number" placeholder="Agregado" [(ngModel)]="agregado" [class]=" agregado!== null ? 'valid' : 'invalid'">
      </div>
      <div class="input-div">
        <input type="number" placeholder="Código de zona" [(ngModel)]="codzona" [class]=" codzona!== null ? 'valid' : 'invalid'">
      </div>
      <div class="input-div">
        <input type="number" placeholder="Recinto" [(ngModel)]="recinto" [class]=" recinto!== null ? 'valid' : 'invalid'">
      </div>
    </div>
    <div class="btn-save">

      <button class="button" expand="block" fill="clear" (click)="create()">
        Guardar
      </button>
    </div>

  
</div>