import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { faCircleUser,} from "@fortawesome/pro-light-svg-icons";
import { ChangePasswordComponent } from 'src/app/shared/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { faArrowLeft,} from "@fortawesome/pro-regular-svg-icons";
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})

export class PerfilComponent {
  admin: boolean = false;
    faArrowLeft=faArrowLeft
faCoffee=faCircleUser;
  user: any={
    email:'Cargando...'
  };
  constructor(private router:Router,private auth:AuthenticationService,private dialog:MatDialog,private meta:Meta,private title:Title) {
    this.title.setTitle('Perfil - Gestiona datos y controla tus cultivos con Fertismart');
    if(this.meta.getTag('name=description')){
      this.meta.updateTag({ name: 'description', content: 'Desde perfil, podrás gestionar fácilmente tus datos de usuario, historial y parcelas' });
    }else{
      this.meta.addTag({ name: 'description', content: 'Desde perfil, podrás gestionar fácilmente tus datos de usuario, historial y parcelas' });
    }
   }
  ngOnInit(): void {
    this.auth.getUsersMe().subscribe(userme => {
      this.auth.getUser(userme.id).subscribe(user => {
        this.admin=user.roles.includes('ROLE_ADMIN')||false;

        this.user=user;
        // console.log(user);


      })


    });

  }

  openDialog() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '400px',data:{email:this.user.email}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }


  goChangePassword(){

    this.openDialog();
  }

  goHistory(){

    this.router.navigateByUrl('/historico');
  }

  goParcela(){

      this.router.navigateByUrl('/parcelas');
  }
  goHome(){
    this.router.navigateByUrl('/');
  }

  goTable(){
    this.router.navigateByUrl('/tabla');
  }

  logout(){
    this.auth.logout();
  }
}
