<h1 mat-dialog-title >Cambiar contraseña</h1>


<div mat-dialog-content>

  <form [formGroup]="changePasswordForm" enctype="application/x-www-form-urlencoded" class="form-login">
    <div class="form-login-content">


      <ng-container *ngIf="!renewLostPass">


        <div class="content-input-form-login-dialog">
          <input id="oldPass" class="input-form-login-dialog" matInput  [type]="hide1 ? 'text' : 'password'" matInput
            placeholder="Contraseña antigua" formControlName="oldPass" autocomplete="off"
            [ngClass]="{error:changePasswordForm.get('oldPass').errors!=null && changePasswordForm.get('oldPass').touched,'valid-input' : changePasswordForm.get('oldPass').errors==null }">
            <span class="msgError" *ngIf="changePasswordForm.get('oldPass').touched && changePasswordForm.get('oldPass').value.length<8">{{msgCustom0}}</span>
            <div class="toogleHide">
              <button type="button" mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide1">
                <mat-icon>{{hide1 ? 'visibility':'visibility_off'}}</mat-icon>
              </button>
            </div>
          </div>
      </ng-container>
      <div class="content-input-form-login-dialog">
        <input id="password" class="input-form-login-dialog" autocomplete="off" matInput [type]="hide2 ? 'text' : 'password'" matInput
          placeholder="Contraseña nueva" formControlName="newpass1"
          [ngClass]="{error:comprobarPass('newpass1'),'valid-input' : changePasswordForm.get('newpass1').errors==null}">
          <span class="msgError" *ngIf="comprobarPass('newpass1')">{{msgCustom1}}</span>
        <div class="toogleHide">
          <button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide2">
            <mat-icon>{{hide2? 'visibility':'visibility_off'}}</mat-icon>
          </button>
        </div>
      </div>
      <div class="content-input-form-login-dialog mb-3">
        <input id="password2" class="input-form-login-dialog" autocomplete="off" matInput [type]="hide3 ? 'text' : 'password'" matInput
          placeholder="Repite la contraseña" formControlName="newpass2"
          [ngClass]="{error:comprobarPass('newpass2'),'valid-input' : changePasswordForm.get('newpass2').errors==null}">
          <span class="msgError" *ngIf="comprobarPass('newpass2')">{{msgCustom2}}</span>
        <div class="toogleHide">
          <button type="button" mat-icon-button matSuffix (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide3">
            <mat-icon>{{hide3 ? 'visibility':'visibility_off'}}</mat-icon>
          </button>
        </div>
      </div>

      <div class="content-input-form-login-dialog">
        <button (click)="changePassword()">Cambiar contraseña</button>
       
      </div>
      <!-- <div class="content-input-form-login-dialog ">
        <button class="different" (click)="onNoClick()">Cancelar</button>
      </div> -->
    </div>
  </form>
</div>