import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { Navigation } from 'swiper';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  adminPermission(): string | boolean {
    throw new Error('Method not implemented.');
  }
  adminDepartamento() {
    throw new Error('Method not implemented.');
  }
  constructor(private http: HttpClient,private common:CommonService,private router:Router) { }

  login(username: string, password: string) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};

    return this.http.post<any>(AppSettings.API_URI + '/login', {username: username, password: password}, formHeaders).pipe(
      map(jwt => {
        if (jwt.token) {
          localStorage.setItem('jwt_token', jwt.token);
        }

        return jwt;
      })
    );
  }

  check() {
    return this.http.get<any>(AppSettings.API_URI + '/users/me').pipe(
      map(data => {
        if (data.code == 401) {
          return false;
        } else if (data.code == 401) {
          return false;
        } else if(data.user != null) {
          return data.user;
        } else {
          return false;
        }
      }, error => {
        return false;
      })
    );
  }

  register(jsonForm: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};

    return this.http.post<any>(AppSettings.API_URI + '/user_register', jsonForm, formHeaders).pipe(
      map(register => {
        return register;
      })
    );
  }

  updateUser(id: any, jsonForm: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};

    return this.http.put<any>(AppSettings.API_URI + '/api/users/'+id, jsonForm, formHeaders).pipe(
      map(updated => {
        return updated;
      })
    );
  }

  passReset(email: string, token: string,password:string){
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};

    return this.http.post<any>(AppSettings.API_URI + '/setresetpasswd', {email: email, token: token,password:password}, formHeaders).pipe(
      map(reset => {
        return reset;
      })
    );
  }

  lostPass(email: string){
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};

    return this.http.post<any>(AppSettings.API_URI + '/createpassword', {email: email}, formHeaders).pipe(
      map(reset => {
        return reset;
      })
    );
  }

  logout() {
    localStorage.removeItem('jwt_token');
    this.router.navigate(['/home']);

    
   
  }

  validateEmail(email: string, token: string ) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/verify_account`;
    return this.http.post<any>(url, {email: email, token: token }, formHeaders).pipe(
      map(
        data => {
          return data;
        }
      )
    );
  }

  getUsersMe() {
    const url = `${ AppSettings.API_URI }/api/user/me`;
    return this.common.getData(url, false, 1);
  }

  getUser(id){
    return this.http.get<any>(AppSettings.API_URI + '/api/users/'+id).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    );
  }

}
