<app-menu [home]="false"></app-menu>

<div class="container padding">
  <h1 class="title-terms">Politica de Cookies</h1>
  <p>La presente Política de Cookies establece el uso de cookies y tecnologías similares ("Cookies") en el sitio web [nombre del sitio web] ("Sitio web") operado por [nombre de la empresa o propietario del sitio web] ("Nosotros" o "nuestro"). Al utilizar el Sitio web, usted acepta el uso de Cookies de acuerdo con esta Política de Cookies.</p>
  <p class="sub-title-terms">¿Qué son las cookies?</p>
  <p>Las cookies son pequeños archivos de texto que se almacenan en su dispositivo (computadora, teléfono móvil u otro dispositivo con acceso a Internet) cuando visita nuestro Sitio web. Las Cookies nos permiten reconocer su dispositivo y obtener información sobre su experiencia de navegación, preferencias y configuraciones. Las Cookies también pueden contener información personal identificable.</p>
  <p class="sub-title-terms">Tipos de cookies utilizadas</p>
  <p>a) Cookies esenciales: Estas cookies son estrictamente necesarias para el funcionamiento del Sitio web. Nos permiten proporcionar los servicios solicitados por usted, como acceder a áreas seguras del Sitio web o realizar transacciones en línea.</p>
  <p>b) Cookies de rendimiento: Estas cookies recopilan información anónima sobre la forma en que los usuarios utilizan nuestro Sitio web. Nos ayudan a mejorar la funcionalidad del Sitio web al recopilar y analizar datos estadísticos sobre el uso del Sitio web, como las páginas visitadas, el tiempo de visita, los errores encontrados y la eficacia de nuestras campañas de marketing.</p>
  <p>c) Cookies de funcionalidad: Estas cookies permiten que el Sitio web recuerde las elecciones que usted ha realizado (como su nombre de usuario, idioma o región) y proporcionen características mejoradas y personalizadas. Estas cookies también pueden utilizarse para recordar los cambios que haya realizado en el tamaño del texto, fuentes y otras partes personalizables del Sitio web.</p>
  <p>d) Cookies de publicidad: Estas cookies se utilizan para mostrar anuncios relevantes para usted y sus intereses. También se utilizan para limitar la cantidad de veces que usted ve un anuncio y para medir la efectividad de una campaña publicitaria. Estas cookies suelen ser colocadas por redes publicitarias de terceros con nuestro permiso.</p>
  <p class="sub-title-terms">Control de cookies</p>
  <p>Puede administrar las Cookies en su dispositivo a través de la configuración del navegador y otras herramientas. La mayoría de los navegadores web le permiten controlar las Cookies a través de sus configuraciones de privacidad. Sin embargo, tenga en cuenta que deshabilitar las Cookies puede afectar la funcionalidad del Sitio web y limitar su capacidad para utilizar todas sus características.</p>
  <p class="sub-title-terms">Cookies de terceros</p>
  <p>Es posible que en nuestro Sitio web se utilicen Cookies de terceros para proporcionar servicios y funciones adicionales. Estos terceros tienen sus propias políticas de privacidad y cookies, y no somos responsables de sus prácticas. Le recomendamos que consulte las políticas de privacidad y cookies de estos terceros para obtener más información sobre sus prácticas y cómo optar por no participar en ellas.</p>
  <p class="sub-title-terms">Cambios en nuestra Política de Cookies</p>
  <p>Nos reservamos el derecho de modificar o actualizar esta Política de Cookies en cualquier momento. Le recomendamos que revise periódicamente esta Política de Cookies para estar al tanto de cualquier cambio. La fecha de la última actualización se indica al principio de esta Política de Cookies.</p>
  <p class="sub-title-terms">Contacto</p>
  <p>Si tiene alguna pregunta o inquietud sobre nuestra Política de Cookies, puede ponerse en contacto con nosotros a través de oficina@internoringenieros.es.</p>
  <p>Al continuar utilizando nuestro Sitio web sin cambiar la configuración de las Cookies en su dispositivo, usted acepta nuestro uso de Cookies de acuerdo con esta Política de Cookies.</p>
</div>
