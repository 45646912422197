import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment-ok',
  templateUrl: './payment-ok.component.html',
  styleUrls: ['./payment-ok.component.scss']
})
export class PaymentOkComponent {

  subscription: any;
  idAnalysis: string= '';

  constructor(private router: Router, private platform: Platform, private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      
      if (params['analysis']) {
        this.idAnalysis = params['analysis'];
      }
    });
  }

  // ionViewDidEnter() {
  //   this.subscription = this.platform.backButton.subscribeWithPriority(9999, () => {
  //     // do nothing
  //   })
  // }

  // ionViewWillLeave() {
  //   this.subscription.unsubscribe();
  // }
  back(){
    this.router.navigateByUrl('/perfil');
  }

}
