import { Component } from '@angular/core';

@Component({
  selector: 'app-aviso-legal',
  templateUrl: './aviso-legal.component.html',
  styleUrls: ['./aviso-legal.component.scss']
})
export class AvisoLegalComponent {
ngOnInit(): void {
  setTimeout(() => {
    window.scrollTo(0, 0);
    }, 100);
}
}
