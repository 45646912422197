import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogClose } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { result } from 'lodash';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss']
})
export class EmailDialogComponent {
dialogResult:MatDialogClose
  emailForm:FormGroup;
  init=true;
  enviar:string;
  
  
  constructor(private dialogRef:DialogRef<EmailDialogComponent>,private formBuilder: FormBuilder, ) {
    this.emailForm = this.formBuilder.group({
      email: ['',[ Validators.required,Validators.email]]
    });
    
   }



validar(){
  if(!this.emailForm.valid) {
    this.dialogRef.disableClose=true;
    this.init=false;
    return false;
  }else{
    this.dialogRef.disableClose=false;
   return true;
  }
  // [mat-dialog-close]="email"
}


  onNoClick(): void {
    this.dialogRef.close();
  }

 
  enviarDatos(){
    document.getElementById("envio").click();

  }
}

