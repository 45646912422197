import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalysisService } from 'src/app/services/analysis.service';
import { VariablesService } from 'src/app/services/variables.service';
import { LoginDialog } from '../../shared/login-dialog/LoginDialog';

@Component({
  selector: 'app-pay-analysis',
  templateUrl: './pay-analysis.component.html',
  styleUrls: ['./pay-analysis.component.scss']
})
export class PayAnalysisComponent {

  email: string;
  link: string;
  idAnalisis: any = '';
  providers: any;
  loading: boolean = false;
  parcel: string;

  constructor(private route: ActivatedRoute, private variableService: VariablesService, private analysisService: AnalysisService,
    private router: Router, private zone: NgZone) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state) {
      if (state['analysis'] && state['email'] && state['parcel']) {
        this.email = state['email'];
        this.parcel = state['parcel']
        this.idAnalisis = state['analysis']
      }
    }

  }

  ngOnInit() {




    // this.providers = params['providers'];
    this.providers = this.variableService.getAbonos();


    this.link = "https://buy.stripe.com/test_8wMcOO7UN4Mm5ZCeUU?client_reference_id=" + this.idAnalisis + "&prefilled_email=" +this.email;

    this.goPay();


  


  }

goPay() {
  this.loading = true;
  this.analysisService.payAnalysis({
    analisisId: this.idAnalisis, abonos: [{
      sementera: this.providers.sementera, cobertera:
        this.providers.cobertera
    }]
  }).subscribe(pay => {
    if (pay.code === '200') {
      window.open(this.link, '_system', 'location=yes')
      this.variableService.setAbonos(null);
      this.loading = false;

      


      this.router.navigate(['/parcelas']);




    }
  }, error => {


    this.loading = false;
  })




}
}
