<div class="container p-sm-auto p-0 pb-4">
    <div class="title-div">
        <div class="header">
          <fa-icon class="faiconBack"  [icon]="faArrowLeft" (click)="goBack()" role="button"></fa-icon>
          <span class="title">Histórico</span>
        </div>
      </div>
      
      <div *ngIf="entries.length===0 && !cargando">
        <div class="mensaje">
          <span >No hay registros...</span>
        </div>
      </div>
      <div *ngIf="cargando">
        <div class="mensaje">
          <span >Cargando...</span>
        </div>
      </div>
        <div role="button" *ngFor="let entry of entries" class="entry" (click)="goToAnalysis(entry)">
          <span>{{entry.nombre |titlecase}}</span>
          <span>{{entry.anoanalisis}}</span>
        </div>
</div>