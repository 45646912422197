import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/**
 * Guards
 */
import { AuthGuard } from './guards/auth.guard';

/**
 * Components
 */
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { HistoryComponent } from './components/history/history.component';
import { DataAnalysisComponent } from './components/data-analysis/data-analysis.component';
import { LoginCheckGuard } from './guards/login-check.guard';
import { ParcelasComponent } from './components/parcelas/parcelas.component';
import { ParcelDetailComponent } from './components/parcel-detail/parcel-detail.component';
import { TablasComponent } from './components/tablas/tablas.component';
import { TableGuard } from './guards/table.guard';
import { EditParcelComponent } from './components/edit-parcel/edit-parcel.component';
import { CrearParcelComponent } from './components/crear-parcel/crear-parcel.component';
import { SelectAnalisysComponent } from './components/select-analisys/select-analisys.component';
import { DataTakingComponent } from './components/data-taking/data-taking.component';
import { ProvidersComponent } from './components/providers/providers.component';
import { PayAnalysisComponent } from './components/pay-analysis/pay-analysis.component';
import { PaymentOkComponent } from './components/payment-ok/payment-ok.component';
import { PaymentKoComponent } from './components/payment-ko/payment-ko.component';
import { AvisoLegalComponent } from './components/aviso-legal/aviso-legal.component';
import { PrivacidadComponent } from './components/privacidad/privacidad.component';
import { SobreNosotrosComponent } from './components/sobre-nosotros/sobre-nosotros.component';
import { CookiesComponent } from './components/cookies/cookies.component';




const routes: Routes = [
  { path: 'home', component: HomeComponent  },
  {
    path: 'perfil',
    component: PerfilComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cookies',
    component: CookiesComponent,
  },
  {
    path: 'legal',
    component: AvisoLegalComponent,
  },
  {
    path: 'privacy',
    component: PrivacidadComponent,
  },
  {
    path: 'about',
    component: SobreNosotrosComponent,
  },

  {
    path: 'historico',
    component: HistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'data-analysis',
    component: DataAnalysisComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'parcelas',
    component: ParcelasComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-parcel',
    component: EditParcelComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'crear-parcela',
    component: CrearParcelComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'parcel-detail',
    component: ParcelDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'select-analysis',
    component: SelectAnalisysComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'data-taking',
    component: DataTakingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'providers',
    component: ProvidersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pay-analysis',
    component: PayAnalysisComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'payment-ko',
    component: PaymentKoComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'payment-ok',
    component: PaymentOkComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tabla',
    component: TablasComponent,
    canActivate: [AuthGuard,TableGuard]
  },
  { path: '**', redirectTo: '/home', pathMatch: 'prefix' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
