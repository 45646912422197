<h1 mat-dialog-title  class="mb-0">{{data.cabecera |colName|titlecase}}</h1>
<div mat-dialog-content class="bg-color-white">
  <div class="edit">

  </div>
  <form [formGroup]="myForm" enctype="application/x-www-form-urlencoded" class="form-login">
    <div class="form-login-content">

      <div class="content-input-form-login-dialog">
        <textarea formControlName="descripcion" name="desc" id="desc" cols="30" rows="5"></textarea>
      </div>

      <div class="button-header">
        <button mat-button (click)="cambiar()" >Aceptar</button>
      </div>

    </div>
  </form>
</div>