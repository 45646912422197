import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import jwt_decode from "jwt-decode";
import { error } from 'console';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private activatedRouter: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var token = localStorage.getItem('jwt_token');
    
    if (localStorage.getItem('jwt_token') === null) {
      this.router.navigate(['/']).then(() => this.authenticationService.logout());
      return of(false);
    }

    var decoded: any = jwt_decode(token);
    if (decoded && decoded.username) {
      return new Observable<boolean>((observer) => {
     
        this.authenticationService.getUsersMe()
        .subscribe((response:any) => {
          if (response.status==401 || response.code==401) {
            localStorage.removeItem('jwt_token');
            this.router.navigate(['/'])
            observer.next(false);

          } else {
            observer.next(true);
          }
        },
        error => {
          localStorage.removeItem('jwt_token');
          this.router.navigate(['/'])
          observer.next(false);
        })
      })
     
    
      


      // return this.authenticationService.getUsersMe().pipe(
      //   switchMap((response: any) => {
      //     console.log(response);
      //     if (response.status == 401 || response.code == 401) {
      //       localStorage.removeItem('jwt_token');
      //       this.router.navigate(['/']);
      //       return of(false);
      //     } else {
      //       return of(true);
      //     }
      //   })
      // );


    }
    this.authenticationService.logout();
    this.router.navigate(['/']).then(() => location.reload());
    return of(false);
  }




}
