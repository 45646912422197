<app-menu></app-menu>

<ng-container (click)="toggleNavbar=true">

  <div class="image-header-top">
    <div class="image-header-container">
      <div class="container content-header">
        <h1 class="bold title-image">Reduce costes y ahorra tiempo</h1>
        <h2 class="subtitle-image-header"><span class="bold">Fertismart</span> es la herramienta digital para que puedas
          lograr el rendimiento más eficiente y sustentable de <span class="bold">la forma más simple.</span></h2>
        <div class="button-header">
          <a class="btn btn-primary" href="home#price-tb" type="button">Realizar un análisis</a>
        </div>
      </div>
    </div>
  </div>

  <div class="scroll-down text-center container">
    <a href="home#first-tb"><fa-icon [icon]="faChevronDown" class="icon-login-header anim-arrow"></fa-icon></a>
  </div>
  <div class="container">
    <div class="features" id="first-tb">
      <div class="first-info row ">
        <div class="col-md">
          <img class="img-fluid img-radius" src="/assets/images/image1-home.png">
        </div>
        <div class="col-md">
          <div class="title-ft">
            <h1>Optimizando la fertilización</h1>
            <h2>para un campo más rentable</h2>
          </div>
          <div class="separator-ft"></div>
          <p class=""><span class="bold-ft">Fertismart</span> es una innovadora aplicación diseñada para revolucionar el
            proceso de fertilización en el campo. Con su avanzado sistema de análisis de suelo y recomendaciones
            precisas
            de fertilizantes, <span class="bold-ft">Fertismart</span> permite a los agricultores y técnicos del sector optimizar el uso de
            fertilizantes, mejorando los rendimientos y reduciendo costes.</p>
          <div class="separator"></div>
          <div class="row icon-ft ">
            <div class="col-6">
              <div>
                <fa-icon [icon]="faMagnifyingGlassChart" class="icon-login-header"></fa-icon>
              </div>
              <div class="label-icon">
                <label>Tu análisis rápido</label>
              </div>
            </div>
            <div class="col-6">
              <div>
                <fa-icon [icon]="faLockKeyhole" class="icon-login-header"></fa-icon>
              </div>
              <div class="label-icon">
                <label>Información segura</label>
              </div>
            </div>
          </div>
          <div class="separator-ft"></div>
          <div class="content-button ">
            <a class="btn btn-primary" href="home#price-tb" type="button">Solicitar un análisis</a>
          </div>
        </div>
      </div>
      <div class="separtor-tabs-ft"></div>
      <div class="first-info row ">
        <div class="order-2  order-md-1 col-md">
          <div class="title-ft">
            <h1>Personalización precisa</h1>
            <h2>para la reducción de costes</h2>
          </div>
          <div class="separator-ft"></div>
          <p class="">Mediante la recopilación de datos sobre las propiedades y componentes del suelo y las
            necesidades específicas de los cultivos, <span class="bold-ft">Fertismart</span> genera un plan de
            fertilización
            personalizado para cada parcela de cultivo. Para que el interesado pueda aplicar la cantidad adecuada de nutrientes, evitando excesos y disminuyendo los costes</p>
          <div class="separator"></div>
          <div class="row icon-ft ">
            <div class="col-6">
              <div>
                <fa-icon [icon]="faPenRuler" class="icon-login-header"></fa-icon>
              </div>
              <div class="label-icon">
                <label>Personalización precisa</label>
              </div>
            </div>
            <div class="col-6">
              <div>
                <fa-icon [icon]="faPiggyBank" class="icon-login-header"></fa-icon>
              </div>
              <div class="label-icon">
                <label>Reducción de costes</label>
              </div>
            </div>
          </div>
          <div class="separator-ft"></div>
          <div class="content-button ">
            <a class="btn btn-primary" href="home#price-tb" type="button">Solicitar un análisis</a>
          </div>
        </div>
        <div class="order-1 order-md-2 col-md">
          <img class="img-fluid  img-radius" src="/assets/images/image2-home.png">
        </div>
      </div>
      <div class="separtor-tabs-ft"></div>
      <div class="first-info row ">
        <div class="col-md">
          <img class="img-fluid img-radius" src="/assets/images/image3-home.png">
        </div>
        <div class="col-md">
          <div class="title-ft">
            <h1>Decisiones inteligentes</h1>
            <h2>Maximizando la eficiencia con Fertismart</h2>
          </div>
          <div class="separator-ft"></div>
          <p class=""><span class="bold-ft">Fertismart</span> ofrece una herramienta de cálculo estratégica para la
            fertilización. Los agricultores y técnicos del sector pueden ingresar los datos sobre los cultivos y las
            propiedades y componentes del suelo en la aplicación, que generará un plan detallado de fertilización a seguir.</p>
          <div class="separator"></div>
          <div class="row icon-ft">
            <div class="col-6">
              <div>
                <fa-icon [icon]="faLightbulbOn" class="icon-login-header"></fa-icon>
              </div>
              <div class="label-icon">
                <label>Maximizando la eficiencia</label>
              </div>
            </div>
            <div class="col-6">
              <div>
                <fa-icon [icon]="faChartLine" class="icon-login-header"></fa-icon>
              </div>
              <div class="label-icon">
                <label>Decisiones inteligentes</label>
              </div>
            </div>
          </div>
          <div class="separator-ft"></div>
          <div class="content-button ">
            <a class="btn btn-primary" href="home#price-tb" type="button">Solicitar un análisis</a>
          </div>
        </div>
      </div>
    </div>
    <div class="separator-price"></div>
  </div>
  <div class="price">
    <div class="container" id="price-tb">
      <h2 class="price-title">Planes de precios</h2>
      <div class="row flex-row">
        <div class="col-md-6 d-flex justify-content-center">
          <div class="card-price">
            <div class="title-price text-center">análisis Gratuito</div>
            <div class="features-price row">
              <div class="col-md-12 d-flex flex-column align-items-center margen">
                <p class="feature-price-line d-flex align-items-center">
                  <fa-icon [icon]="faCircleCheck" class="price-icon-check"></fa-icon> <span
                    class="price-feature-list">Realiza una interpretación del análisis asociado a la parcela específica introduciendo datos
                    relevantes del suelo</span>
                </p>
                <p class="feature-price-line d-flex align-items-center">
                  <fa-icon [icon]="faCircleCheck" class="price-icon-check"></fa-icon> <span
                    class="price-feature-list">Evalúa la fertilidad del suelo utilizando los resultados del
                    análisis</span>
                </p>
                <p class="feature-price-line d-flex align-items-center">
                  <fa-icon [icon]="faCircleCheck" class="price-icon-check"></fa-icon> <span
                    class="price-feature-list">Obtén una interpretación de los elementos en el suelo</span>
                </p>
                <p class="feature-price-line d-flex align-items-center">
                  <fa-icon [icon]="faCircleCheck" class="price-icon-check"></fa-icon> <span
                    class="price-feature-list">Realiza valoraciones específicas para cada elemento químico presente en
                    el
                    suelo</span>
                </p>
              </div>
            </div>
            <div class="content-button text-center">
              <button class="btn btn-primary" type="button" (click)="openDialog()">Solicitar análisis</button>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex justify-content-center">
          <div class="card-price">
            <div class="title-price text-center">análisis Profesional</div>
            <div class="features-price row">
              <div class="col-md-12 d-flex flex-column align-items-center margen">
                <p class="feature-price-line d-flex align-items-center">
                  <fa-icon [icon]="faCircleCheck" class="price-icon-check"></fa-icon> <span
                    class="price-feature-list">Recibe recomendaciones personalizadas en relación a la composición del
                    suelo</span>
                </p>
                <p class="feature-price-line d-flex align-items-center">
                  <fa-icon [icon]="faCircleCheck" class="price-icon-check"></fa-icon> <span
                    class="price-feature-list">Obtén información adicional sobre elementos menores, saturación de bases
                    y
                    parámetros relevantes</span>
                </p>
                <p class="feature-price-line d-flex align-items-center">
                  <fa-icon [icon]="faCircleCheck" class="price-icon-check"></fa-icon> <span
                    class="price-feature-list">Realiza un cálculo campleto del abonado y obtén información detallada
                    sobre
                    el tipo de fertilizante</span>
                </p>
                <p class="feature-price-line d-flex align-items-center">
                  <fa-icon [icon]="faCircleCheck" class="price-icon-check"></fa-icon> <span
                    class="price-feature-list">Selecciona los proveedores que desees e incluye la cantidad de kilos
                    requerida</span>
                </p>
              </div>
            </div>
            <div class="content-button text-center">
              <button class="btn btn-primary" type="button" (click)="openDialog()">Solicitar análisis</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="download-app" id="#download-app">
    <div class="container container-download-app" id="download-app">
      <div class="row">
        <div class="col-md-6 order-2 order-md-1">
          <div class="app-img">
            <img class="image-mockup" src="/assets/images/app_image.png" alt="...">
          </div>
        </div>
        <div class="col-md-6 order-1 order-md-2">
          <div class="app-title">La app oficial de <span class="bold"> Fertismart</span> análisis del campo</div>
          <div class="app-subtitle">¡Todo en una única plataforma!</div>
          <div class="app-image-download">
            <a href="" class="link-app-store">
              <img class="img1" src="/assets/images/google-play-badge.svg" alt="...">
            </a>
            <a href="" class="link-app-store" target="_blank">
              <img class="img2" src="/assets/images/app-store.svg" alt="...">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <app-contacto></app-contacto> -->

  <div class="separator-footer"></div>
  <div class="footer">
    <div class="background-global-footer">
      <div class="footer-component">
        <div class="comment-footer text-center ps-2 pe-2 ">Solicita hoy tu informe con <span
            class="bold">Fertismart</span>
        </div>
        <div class="background-footer">
          <div class="menu-footer w-100">
            <div class="container-lg d-md-flex justify-content-between justify-content-md-around myfooter">
              <div class="d-grid justify-content-center grid-group">
                <div class="footerHead" (click)="internor=!internor">
                  <p class="title-menu-footer">
                    Fertismart
                  </p>
                  <fa-icon [icon]="internor ? faChevronUp : faChevronDown" class="price-icon-check"></fa-icon>
                  </div>
                  <div class="options-menu" [class.open-menu]="internor">
                    <p (click)="goTo('home#')" routerLink="home#"> Inicio</p>
                    <p (click)="goTo('home#first-tb')" >Características</p>
                    <p (click)="goTo('home#price-tb')" >Precio</p>
                    <!-- <p (click)="goTo('home#price-tb')" >Solicitar informe</p> -->
                  </div>
              </div>
              <div class="d-grid justify-content-center grid-group">
                <div class="footerHead" (click)="legal=!legal">
                  <p class="title-menu-footer">
                    Legal
                  </p>
                  <fa-icon [icon]="legal ? faChevronUp : faChevronDown" class="price-icon-check"></fa-icon>
                </div>
                <div class="options-menu" [class.open-menu]="legal">
                  <p routerLink="/privacy">Politica de privacidad</p>
                  <p routerLink="/legal">Aviso legal</p>
                  <p routerLink="/cookies">Politica de cookies</p>
                </div>
              </div>
              <div class="d-grid justify-content-center grid-group">
                <div class="footerHead" (click)="contacto=!contacto">
                  <p class="title-menu-footer">
                    Contacto
                  </p>
                  <fa-icon [icon]="contacto ? faChevronUp : faChevronDown" class="price-icon-check"></fa-icon>
                </div>
                <div class="options-menu" [class.open-menu]="contacto">
                  <p class="cursor"  >LOGROÑO</p>
                  <p class="cursor">Internor Ingenieros</p>
                  <p class="cursor">Calle Portillejo 20</p>
                  <p class="cursor">26007 | Logroño (La Rioja)</p>
                </div>
              </div>

            </div>
          </div>
          <div class="w-100 bg-light">

            <div class="footer-copyright  container-lg d-md-flex justify-content-between justify-content-md-around  ">
              <div class="d-grid justify-content-start grid-group ">
                <p class="m-0">© Kreare Mobile 2023</p>
              </div>
              <div>
                <img src="../../../assets/images/finan-removebg-preview.png" alt="financiado por la unión europea" class="logosFinan">
                <img src="../../../assets/images/removebg-preview.png" alt="Plan de recuperación. Transformación y resilencia" class="logosFinan">

              </div>
              <div class="d-grid justify-content-start grid-group "></div>
              <div class="d-grid justify-content-start grid-group "> <p class="m-0">Desarrollado con <fa-icon  [icon]="faHeart"></fa-icon> por SDi </p></div>


            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</ng-container>
