import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { AppSettings } from '../app-settings';


@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  constructor(private http: HttpClient) { }

  postDataAnalysis(dataAnalysis:any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/saveanalisis`;
    return this.http.post<any>(url, dataAnalysis, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(data);
      }), catchError((err) => {

        return of(err.error);
      })
    );
  }

  getAnalysis(id: any) {
    return this.http.get<any>(AppSettings.API_URI + '/api/datosanalises/'+id).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    );
  }

  payAnalysis(data:any){
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/topayanalisis`;
    return this.http.post<any>(url, data, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  getAnalysisParcela(data: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/datosanalisisaparcela`;
    return this.http.post<any>(url, data, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  paymentOk(id: any){
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/paymentok/`+id;
    return this.http.post<any>(url, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }
}
