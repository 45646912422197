import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EntitysService } from 'src/app/services/entitys.service';
import { VariablesService } from 'src/app/services/variables.service';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { NotificacionDialogComponent } from '../../shared/notificacion-dialog/notificacion-dialog.component';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent {
  btnAceptar:boolean=false;
  faArrowLeft=faArrowLeft;

  abonos: any[] = [];
  selectedS: number[] = [];
  selectedC: number[] = [];
  openModal: boolean = false;
  openSelect: boolean = false;
  type: string = 'Tipo';

  NselS: boolean = false;
  PselS: boolean = false;
  KselS: boolean = false;
  SselS: boolean = false;
  CAselS: boolean = false;
  MGselS: boolean = false;

  NselC: boolean = false;
  PselC: boolean = false;
  KselC: boolean = false;
  SselC: boolean = false;
  CAselC: boolean = false;
  MGselC: boolean = false;

  section: number = 1;

  analysis:any;
  email:any;
  parcel:any
  year:any;

  constructor(private location: Location, private variableService: VariablesService, private entityService: EntitysService,
      private route: ActivatedRoute, private router: Router,private dialog:MatDialog) { 
        const state=this.router.getCurrentNavigation().extras.state;        
        if(state){
          if (state['analysis']&&state['email']&&state['parcel']) {
            this.analysis=state['analysis'];
            this.email=state['email'];
            this.parcel=state['parcel'];
            this.year=state['year'];
          }}
      }

  ngOnInit() {
    this.entityService.getAbonosProductos().subscribe(data => {
      
      this.abonos = data;
    });
  }

  selectOptionS(i: number) {
    const index = this.selectedS.indexOf(i);
    if (index > -1) {
      this.setUsedElementsS(this.abonos[this.selectedS[index]]);
      this.selectedS.splice(index, 1);
    } else {
      this.selectedS.push(i);
      this.setUsedElementsS(this.abonos[this.selectedS[this.selectedS.length - 1]]);
    }

  }

  getClassAbonoS(index: any, item: any): string {
    if (this.selectedS.includes(index)) {
      return 'entry-selected';
    } else {
      if (item.n && this.NselS) {
        return 'entry-disabled';
      }
      if (item.k2o && this.KselS) {
        return 'entry-disabled';
      }
      if (item.p2o5 && this.PselS) {
        return 'entry-disabled';
      }
      if (item.ca && this.CAselS) {
        return 'entry-disabled';
      }
      if (item.s && this.SselS) {
        return 'entry-disabled';
      }
      if (item.mg && this.MGselS) {
        return 'entry-disabled';
      }
      return 'entry';
    }
  }

  setUsedElementsS(item: any) {
    if (item.n) {
      this.NselS = !this.NselS;
    }
    if (item.k2o) {
      this.KselS = !this.KselS;
    }
    if (item.p2o5) {
      this.PselS = !this.PselS;
    }
    if (item.ca) {
      this.CAselS = !this.CAselS;
    }
    if (item.s) {
      this.SselS = !this.SselS;
    }
    if (item.mg) {
      this.MGselS = !this.MGselS;
    }
  }



  selectOptionC(i: number) {
    const index = this.selectedC.indexOf(i);
    if (index > -1) {
      this.setUsedElementsC(this.abonos[this.selectedC[index]]);
      this.selectedC.splice(index, 1);
    } else {
      this.selectedC.push(i);
      this.setUsedElementsC(this.abonos[this.selectedC[this.selectedC.length - 1]]);
    }
  }

  getClassAbonoC(index: any, item: any): string {
    if (this.selectedC.includes(index)) {
      return 'entry-selected';
    } else {
      if (item.n && this.NselC) {
        return 'entry-disabled';
      }
      if (item.k2o && this.KselC) {
        return 'entry-disabled';
      }
      if (item.p2o5 && this.PselC) {
        return 'entry-disabled';
      }
      if (item.ca && this.CAselC) {
        return 'entry-disabled';
      }
      if (item.s && this.SselC) {
        return 'entry-disabled';
      }
      if (item.mg && this.MGselC) {
        return 'entry-disabled';
      }
      return 'entry';
    }
  }

  setUsedElementsC(item: any) {
    if (item.n) {
      this.NselC = !this.NselC;
    }
    if (item.k2o) {
      this.KselC = !this.KselC;
    }
    if (item.p2o5) {
      this.PselC = !this.PselC;
    }
    if (item.ca) {
      this.CAselC = !this.CAselC;
    }
    if (item.s) {
      this.SselC = !this.SselC;
    }
    if (item.mg) {
      this.MGselC = !this.MGselC;
    }
  }

  clickTypeOption(option: string) {
    this.type = option;
    this.openSelect = false;
  }

  save(){
    this.btnAceptar=true;
    let obj = {
      sementera: [],
      cobertera: []
    }

    this.selectedS.forEach(element => {
      obj.sementera.push(this.abonos[element].id);
    });

    this.selectedC.forEach(element => {
      obj.cobertera.push(this.abonos[element].id);
    });
    if(obj.sementera.length == 0 && obj.cobertera.length == 0){

    this.openNotification();
    this.btnAceptar=false;
    return;
    
    }
    this.btnAceptar=true;
    
    let navigationExtras: NavigationExtras = {
      state: {
        email: this.email,
        analysis: this.analysis,
        parcel: this.parcel,
        year: this.year,

      }
    };

    

    setTimeout(() => {
      this.variableService.setAbonos(obj);
      
      this.router.navigate(['/pay-analysis'], navigationExtras);
    }, 1000);

  }

  goBack() {
    this.location.back();
    // if (this.openModal) {

    //   setTimeout(() => {

    //     //this.mapPage.openSheetModal();
    //   }, 100);
    // }
  }

  openNotification(){
    const enterAnimationDuration='200ms';
    const exitAnimationDuration='200ms';
    this.dialog.open(NotificacionDialogComponent, {
      data: { msg: 'Por favor, seleccione una opción mínimo.', botones: 1, title: 'Seleccione una opción' },
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });


  }
}
