import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';
import {  Router } from '@angular/router';
// import * as bcrypt from 'bcryptjs';
import { EmailDialogComponent } from '../email-dialog/email-dialog.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NotificacionDialogComponent } from '../notificacion-dialog/notificacion-dialog.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { error } from 'console';

const bcrypt = require('bcryptjs');
@Component({
  selector: 'login-dialog',
  templateUrl: 'LoginDialog.html',
  styleUrls: ['LoginDialog.scss']
})
export class LoginDialog {
  customMsg1=''
  customMsg2=''
  hide1: boolean = true;
  hide2: boolean = true;
  loginInit=true;
  registerForm: FormGroup;
  loginForm: FormGroup;
  message = "";
  register:boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  bcrypt=bcrypt;
 
  constructor(
    public dialogRef: MatDialogRef<LoginDialog>,
    public dialogRefEmail: MatDialogRef<EmailDialogComponent>,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.loginForm = this.formBuilder.group({
      username: ['',[ Validators.required,Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });

    this.registerForm = this.formBuilder.group({
      email: ['',[ Validators.required,Validators.email]],
      pass: ['', [Validators.required, Validators.minLength(8)]],
      pass2: ['', [Validators.required, Validators.minLength(8)]]

  });
}


  login() {
   
    
    

    if(this.loginForm.invalid) {
      this.loginInit=false;
      this.openDialog('0.25s', '0.25s', 'Rellene todos los campos', 1, "Error");
      return false;
    }

    this.authenticationService.login(this.loginForm.value.username, this.loginForm.value.password).pipe(first()).subscribe(
        data => {
          if (data.jwt != '') {

            this.dialogRef.close();
            this.router.navigateByUrl("/perfil");
            return true;
          } else {

            this.openDialog('0.25s', '0.25s', 'Credenciales inválidas', 1,  "Advertencia");
            return false;
          }
        },
        error => {
          this.openDialog('0.25s', '0.25s', 'Credenciales inválidas', 1,"Advertencia");
        }
      );
  }

  registerUser() {
    if( this.registerForm.value.pass.length<8 || this.registerForm.value.pass2.length<8) {
      this.openDialog('0.25s', '0.25s', 'La contraseña debe tener al menos 8 caracteres', 1,"Advertencia");
      return false;
    }


    if(this.registerForm.value.pass != this.registerForm.value.pass2) {
      this.openDialog('0.25s', '0.25s', 'Las contraseñas no coinciden', 1,"Advertencia");
      return false;
    }

    if(!this.registerForm.valid) {
      this.loginInit=false;
      this.openDialog('0.25s', '0.25s', 'Por favor, rellene todos los campos', 1,"Advertencia");
      return false;
    }


    let salt = bcrypt.genSaltSync(10);
    const hash = bcrypt.hashSync(this.registerForm.value.pass, salt);

    let email= this.registerForm.value.email;
    this.authenticationService.register({email: email, password: hash})
      .pipe(first())
      .subscribe(
        data => {
          if(data.code==200){
            this.router.navigate(['/home']);
            this.openDialog('0.25s', '0.25s', data.message, 1,data.title);
            this.dialogRef.close();
            return true;
          }else{
            this.openDialog('0.25s', '0.25s', data.message, 1,data.title);
            return false;
          }
         
      
        },
        error => {
         const err=JSON.parse(error.error) 
          this.registerForm.get('email').setErrors({'incorrect': true});
          this.openDialog('0.25s', '0.25s', err.message, 1,err.title);

      
        }
      );

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  enviarEmail(){
    const dialogRef = this.dialog.open(EmailDialogComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!result) return false;
      
      
      this.authenticationService.lostPass(result).subscribe(async data => {


        if(data.code == 200) {
          this.openDialog('0.25s', '0.25s', 'Email enviado', 1,data.title);

        }
      });

    })

  }

  openSnackBar(text:string,posicion:any='bottom') {
    const verticalPosition: MatSnackBarVerticalPosition = posicion;
    this._snackBar.open(text, "Aceptar", {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: verticalPosition,
      

    });
  }
  openDialog(enterAnimationDuration: string, exitAnimationDuration: string,mensaje,botones,title): void {
    this.dialog.open(NotificacionDialogComponent, {
      data: {msg:mensaje,botones:botones,title:title},
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  comprobarPass(nombre:string){
    if(nombre=='pass2'){
      if(this.registerForm.get('pass2').value.length<8 && this.registerForm.get('pass2').touched){
        this.customMsg2='La contraseña debe tener al menos 8 caracteres';
        return true;
      }else if(this.registerForm.get('pass2').value.length>=8 && this.registerForm.get('pass').touched && this.registerForm.get('pass').value !== this.registerForm.get('pass2').value){
        this.customMsg2='Las contraseñas no coinciden';
        return true;
      }

      return false;
    
    }
    if(nombre="pass"){
      if(this.registerForm.get('pass').value.length<8 && this.registerForm.get('pass').touched){
        this.customMsg1='La contraseña debe tener al menos 8 caracteres';
        return true;
      }else if(this.registerForm.get('pass').value.length>=8 && this.registerForm.get('pass2').touched && this.registerForm.get('pass').value !== this.registerForm.get('pass2').value){
        this.customMsg1='Las contraseñas no coinciden';
        return true;
      }

      return false;
    }

    // return (this.registerForm.get(nombre).errors!=null && this.registerForm.get(nombre).touched ) ||
    //  ((this.registerForm.get('pass').value !== this.registerForm.get('pass2').value) && 
    //  (this.registerForm.get('pass').touched && this.registerForm.get('pass2').touched ))
 }

 comprobarCampo(campo:string){
  if((this.loginForm.get(campo).invalid && this.loginForm.get(campo).touched) || (!this.loginInit && this.loginForm.get(campo).invalid)  ){
    // this.errorMsg.push(campo);
    return true;
  }
  return false;
}


}
