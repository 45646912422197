import { Component, HostListener, OnInit, SimpleChanges, ViewChild, Input, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialog } from '../../shared/login-dialog/LoginDialog';
import { Platform } from '@angular/cdk/platform';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DownloadAppComponent } from 'src/app/shared/download-app/download-app.component';
import { faCircleUser } from "@fortawesome/pro-light-svg-icons";
import { faLacrosseStick, faLoveseat } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  public toggleNavbar: boolean = true;
  public isFixed: boolean = false;
  faCircleUser = faCircleUser;
  login: boolean = false;
  home: boolean = false;
  @Input('home') isHome: boolean = true;
  @ViewChild("nav") navElement: any;
  @ViewChild("buttonNav") navButton: any;
  loginDialog: boolean = false;


  @HostListener('window:scroll', []) onScroll(): void {

    if (window.scrollY < 200 && this.home) {
      this.isFixed = false;
      this.navElement.nativeElement.classList.add('headerTransparent');
      this.navElement.nativeElement.classList.remove('headerWhite');
      this.navButton.nativeElement.classList.add('navbar-toggler-icon');
      this.navButton.nativeElement.classList.remove('navbar-toggler-iconBlack');
    }
    else if (this.home) {
      this.isFixed = true;
      this.navElement.nativeElement.classList.add('headerWhite');
      this.navElement.nativeElement.classList.remove('headerTransparent');
      this.navButton.nativeElement.classList.add('navbar-toggler-iconBlack');
    } else if (!this.home) {
      this.isFixed = true;
      this.navElement.nativeElement.classList.add('headerWhite');
      this.navElement.nativeElement.classList.remove('headerTransparent');
      this.navButton.nativeElement.classList.add('navbar-toggler-iconBlack');
    }
  }

  ngOnInit(): void {
    document.addEventListener('click', this.onDocumentClick.bind(this));
  }

  onDocumentClick(event: MouseEvent) {

    try {
      const targetElement = event.target as HTMLElement;
      const navElement = this.elementRef.nativeElement.querySelector('.navbar-collapse');

      if (!navElement.contains(targetElement)) {
        this.cerrar();
      }

    } catch (error) {
    }
  }
  checkRoute() {
    if (this.router.url.includes('home')) {
      this.home = true;
    }

    if (this.isHome) {
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          var url = event.url;
          this.home = event.urlAfterRedirects.indexOf('/home') == -1 ? false : true;
        }

      }
      );
    } else {
      this.home = false;

    }
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      if (!this.isHome) {
        this.isFixed = true;
        this.navElement.nativeElement.classList.remove('headerTransparent');
        this.navElement.nativeElement.classList.add('headerWhite');
      }
    }, 5);


  }

  constructor(
    private router: Router, public dialog: MatDialog, private route: ActivatedRoute, private auth: AuthenticationService,
    private platform: Platform, private elementRef: ElementRef
  ) {



    this.checkRoute();


    try {
      this.auth.getUsersMe().subscribe((response: any) => {
  

        if (response.status) {
          this.login = false;
          localStorage.removeItem('jwt_token');

        } else {
          this.login = true;
        }
      }, error => {
        this.login = false;
        localStorage.removeItem('jwt_token');

      });
    } catch (error) {
      localStorage.removeItem('jwt_token');

      this.login = false;
    }
  }

  openDialog() {
    if (this.loginDialog) return;
    if (this.platform.ANDROID || this.platform.IOS) {
      // this.downloadApp();
      // this.router.navigate(['/home#download-app']);
      location.href = "./home#download-app"
    }
    else {
      this.loginDialog = true;
      const dialogRef = this.dialog.open(LoginDialog, {
        width: '400px',
      });

      dialogRef.afterClosed().subscribe(result => {
        this.loginDialog = false;

      });
    }
  }

  downloadApp() {
    const dialogRef = this.dialog.open(DownloadAppComponent, {
      width: '100vw',
      height: '100vh',

    });


  }

  goToProfile() {
    if (this.platform.ANDROID || this.platform.IOS) {
      location.href = "./home#download-app"
    } else {
      this.auth.getUsersMe().subscribe((response: any) => {
        if (response.error) {
          this.login = false;
        }
        if (response.id) {
          this.router.navigate(['/perfil']);
        }
      }, error => {
        this.login = false;
      }
      );



    }
  }
  cerrar() {
    document.getElementById("navbarColor01").classList.remove("show");
  }
}
