<div class="container p-sm-auto p-0">

    <div class="title-div">
        <div class="header">
          <fa-icon role="button" class="faiconBack" [icon]="faArrowLeft" (click)="goBack()"></fa-icon>
          <span class="title ms-3">Tipo de análisis</span>
        </div>
      </div>
      <div class="button-section">

<div class="card card-free card-content " role="button"  (click)="goToFree()">
  <div class="card-body">
    <h5 class="card-title">Análisis gratuito</h5>
    <h6 class="card-subtitle mb-2 text-muted">Opción sin coste</h6>
    <p class="card-text">  Valoración de los niveles de cada elemento.</p>
  </div>
</div>

<div class="card card-paid card-content " role="button"  (click)="goToPaid()">
  <div class="card-body">
    <h5 class="card-title">Análisis avanzado</h5>
    <h6 class="card-subtitle mb-2 text-muted">Opción completa</h6>
    <p class="card-text">  Recomendaciones y cálculo de la cantidad abono.</p>
  </div>
</div>
</div>