import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'


/**
 * Angular Material
 */

import { MaterialModule } from './material.module';

/**
 * Bootstrap
 */

import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

/**
 * JWT
 */
import { JwtInterceptor } from './helpers/jwt.interceptor';

/**
 * Components
 */
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CookiesComponent } from './components/cookies/cookies.component';


/**
 * Shared
 */
import { PerfilComponent } from './components/perfil/perfil.component';
import { HistoryComponent } from './components/history/history.component';
import { DataAnalysisComponent } from './components/data-analysis/data-analysis.component';
import { ParcelasComponent } from './components/parcelas/parcelas.component';
import { ParcelDetailComponent } from './components/parcel-detail/parcel-detail.component';
import { TablasComponent } from './components/tablas/tablas.component';
import { SharedModule } from './shared/shared.module';
import { EditParcelComponent } from './components/edit-parcel/edit-parcel.component';
import { CrearParcelComponent } from './components/crear-parcel/crear-parcel.component';
import { DataTakingComponent } from './components/data-taking/data-taking.component';
import { SelectAnalisysComponent } from './components/select-analisys/select-analisys.component';
import { ProvidersComponent } from './components/providers/providers.component';
import { PayAnalysisComponent } from './components/pay-analysis/pay-analysis.component';
import { PaymentKoComponent } from './components/payment-ko/payment-ko.component';
import { PaymentOkComponent } from './components/payment-ok/payment-ok.component';
import { PrivacidadComponent } from './components/privacidad/privacidad.component';
import { AvisoLegalComponent } from './components/aviso-legal/aviso-legal.component';
import { SobreNosotrosComponent } from './components/sobre-nosotros/sobre-nosotros.component';
import { ColNamePipe } from './pipes/col-name.pipe';
import { PipeModule } from './pipes/pipe.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PerfilComponent,
    HistoryComponent,
    DataAnalysisComponent,
    ParcelasComponent,
    ParcelDetailComponent,
    TablasComponent,
    EditParcelComponent,
    CrearParcelComponent,
    DataTakingComponent,
    SelectAnalisysComponent,
    ProvidersComponent,
    PayAnalysisComponent,
    PaymentKoComponent,
    PaymentOkComponent,
    PrivacidadComponent,
    AvisoLegalComponent,
    SobreNosotrosComponent,
    CookiesComponent,



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgbCollapseModule,
    MaterialModule,
    FontAwesomeModule,
    SharedModule,
    PipeModule,
    NgxSkeletonLoaderModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
