<div class="container p-sm-auto p-0">
    <div class="title-div">
      <div class="header">
        <fa-icon class="faiconBack" [icon]="faArrowLeft" (click)="goHome()" role="button"></fa-icon>
        <span class="title ms-3">Perfil</span>
      </div>
        <div >
          <p class="action" (click)="logout()" role="button">Cerrar sesión</p>
        </div>
      </div>
      <div class="second-div">
        <div class="icon-div">
          <fa-icon [icon]="faCoffee"></fa-icon>
          <i class="fas fa-coffee"></i>
        </div>
        <div>
          <p class="email">{{user.email}}</p>
          <p class="action" role="button" (click)="goChangePassword()">Cambiar contraseña</p>
        </div>
      </div>

      <div class="option" (click)="goHistory()">
        <p>Histórico</p>
      </div>
      <div class="option" (click)="goParcela()">
        <p>Parcelas</p>
      </div>
      <div *ngIf="admin" class="option" (click)="goTable()">
        <p>Administrador</p>
      </div>

      <div class="data-div">
        <div class="d-flex justify-content-between align-content-center data-section">
          <span>Nombre</span>
          <span>{{user.nombre}}</span>
        </div>
        <div class="d-flex justify-content-between align-content-center data-section">
          <span>Email</span>
          <span>{{user.email}}</span>
        </div>
        <div class="d-flex justify-content-between align-content-center data-section">
          <span>Telefono</span>
          <span>{{user.telefono}}</span>
        </div>
        <div class="d-flex justify-content-between align-content-center data-section">
          <span>Direccion</span>
          <span>{{user.direccion}}</span>
        </div>
        <div class="d-flex justify-content-between align-content-center data-section">
          <span>Localidad</span>
          <span>{{user.localidad}}</span>
        </div>
        <div class="d-flex justify-content-between align-content-center data-section">
          <span>Provincia</span>
          <span>{{user.provincia}}</span>
        </div>
      </div>
</div>
