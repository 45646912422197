

  <h1 mat-dialog-title class="font" >{{data.title || 'Información'}}</h1>

  <div mat-dialog-content class="fontd">
   {{data.msg}}
  </div>
  <div mat-dialog-actions>
  
    <button mat-button mat-dialog-close cdkFocusInitial class="cw" >Ok</button>
  </div>
