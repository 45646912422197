import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent {



menu = [
  {
    nombreTabla: 'ph',
    name: 'PH'
  },
  {
    nombreTabla: 'matorganica',
    name: 'Materia Orgánica'
  },
  {
    nombreTabla: 'nitroelemental',
    name: 'Nitrógeno'
  },
  {
    nombreTabla: 'cnrelacion',
    name: 'CN'
  },
  {
    nombreTabla: 'fosforo',
    name:   'Fósforo'
  },
  {
    nombreTabla: 'potasio',
    name: 'Potasio'
  },
  {
    nombreTabla: 'calcio',
    name: 'Calcio'
  },
  {
    nombreTabla: 'magnesio',
    name: 'Magnesio'
  },
  {
    nombreTabla: 'sodio',
    name: 'Sodio'
  },
  {
    nombreTabla: 'aluminio',
    name: 'Aluminio'
  },
  {
    nombreTabla: 'carbonatocalcico',
    name: 'Carbonato calcico'
  },
  {
    nombreTabla: 'calizactiva',
    name: 'Caliza'
  },
  {
    nombreTabla: 'cobre',
    name: 'Cobre'
  },
  {
    nombreTabla: 'hierro',
    name: 'Hierro'
  },
  {
    nombreTabla: 'manganeso',
    name: 'Manganeso'
  },
  {
    nombreTabla: 'sulfato',
    name: 'Sulfato'
  },
  {
    nombreTabla: 'zinc',
    name: 'Zinc'
  },



]

roleAdmin: string | boolean = false;

constructor(private authenticationService: AuthenticationService) { }

ngOnInit(): void {
  this.roleAdmin = this.authenticationService.adminPermission();
}



}
