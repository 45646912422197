<app-menu></app-menu>
<div class="container padding">
    <h3 class="title-terms">Sobre nosotros</h3>
    <p class="sub-title-terms">Lorem ipsum dolor, sit amet consectetur adipisicing elit. </p>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi eos itaque cupiditate autem nemo impedit error at quos?
         Modi eius libero consequatur similique doloribus quibusdam, ullam rerum repudiandae ipsa adipisci?orem
         ipsum dolor sit amet consectetur adipisicing elit. Excepturi eos itaque cupiditate autem nemo impedit error at quos?
         Modi eius libero consequatur similique doloribus quibusdam, ullam rerum repudiandae ipsa adipisci?
         orem ipsum dolor sit amet consectetur adipisicing elit. Excepturi eos itaque cupiditate autem nemo impedit error at quos?
         Modi eius libero consequatur similique doloribus quibusdam, ullam rerum repudiandae ipsa adipisci?</p>
         <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi eos itaque cupiditate autem nemo impedit error at quos?
            Modi eius libero consequatur similique doloribus quibusdam, ullam rerum repudiandae ipsa adipisci?orem
            ipsum dolor sit amet consectesci?</p>
</div>

