<div class="container p-sm-auto p-0" *ngIf="parcelSelected">
  <div class="title-div">
    <div class="header">
      <fa-icon class="faiconBack" [icon]="faArrowLeft" (click)="goBack()" role="button"></fa-icon>
      <span class="title"> {{parcelSelected.nombre}}</span>
    </div>
    <fa-icon role="button" [icon]="faPenCircle" (click)="goEdit()"></fa-icon>
  </div>
  <div [hidden]="!showResume"
    [style]="!analysis ? 'background: var(--color-grey-3)' : 'background: var(--color-green-2)'" class="p-3 mb14">
    <app-parcel-resume [parcel]="parcelSelected"></app-parcel-resume>
  </div>

  <div [hidden]="showResume">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{height:'127px'}"></ngx-skeleton-loader>
  </div>
  <div [hidden]="!showResume" *ngIf="!analysis" class="center ">

    <div class="info-msg text">
      <fa-icon [icon]="faCircleExclamation"></fa-icon>
      <div>
        <span>Sube un análisis de una muestra de suelo para recibir recomendaciones</span>
      </div>
    </div>
    <!-- <div class="chipAnalisis">
      <mat-chip-option class="button-chip" outline="true" (click)="goToDataTaking()">
        <fa-icon [icon]="faPenCircle"></fa-icon>
        <span>Subir análisis de suelo</span>
      </mat-chip-option>
    </div> -->
  </div>

  <div *ngIf="analysis" class="analisis">
    <!-- <mat-chip-option class="button-chip"  outline="true" (click)="goToDataTaking()">
              <fa-icon style=" margin-top: 2px;" [icon]="faPenCircle"></fa-icon>
              <span class="ms-3">Analizar suelo</span>
          </mat-chip-option> -->
    <mat-chip-option class="button-chip " outline="true" (click)="goToAnalysis()">
      <fa-icon [icon]="faClipboardList"></fa-icon>
      <span class="ms-3">Ver análisis</span>
    </mat-chip-option>
  </div>

  <!-- <app-download-app (close)="analice=false"*ngIf="analice" style="position:absolute; z-index:60 ;
        top: 300px; left: 0; right: 0 ;"></app-download-app>
       -->
  <div class="containerEntry">
    <div class=list-entry>
      <span class="elem-name">Provincia</span>
      <span class="elem-cant">{{parcelSelected.provincia}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Localidad</span>
      <span class="elem-cant">{{parcelSelected.localidad}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Municipio</span>
      <span class="elem-cant">{{parcelSelected.municipio}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Paraje</span>
      <span class="elem-cant">{{parcelSelected.paraje}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Capa arable</span>
      <span class="elem-cant">{{parcelSelected.capaarable}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Producción esperada</span>
      <span class="elem-cant">{{parcelSelected.prodesperada}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Superficie de siembra</span>
      <span class="elem-cant">{{parcelSelected.supersiembra}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Código de zona</span>
      <span class="elem-cant">{{parcelSelected.codzona}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Agregado</span>
      <span class="elem-cant">{{parcelSelected.agregado}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Recinto</span>
      <span class="elem-cant">{{parcelSelected.recinto}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Referencia catastral</span>
      <span class="elem-cant">{{parcelSelected.refcatastral}}</span>
    </div>
    <div class="list-entry"></div>
    <div class="section-title">
      <span>Datos sigpac</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Provincia SigPac: </span>
      <span class="elem-cant">{{parcelSelected.provinciasigpac}}</span>
      <span class="elem-name">Municipio SigPac: </span>
      <span class="elem-cant">{{parcelSelected.municipiosigpac}}</span>
    </div>
    <div class=list-entry>
      <span class="elem-name">Parcela SigPac: </span>
      <span class="elem-cant">{{parcelSelected.parcela}}</span>
      <span class="elem-name">Polígono SigPac: </span>
      <span class="elem-cant">{{parcelSelected.poligono}}</span>
    </div>
    <div class=list-entry *ngIf="infoSigpac && infoSigpac[0]">
      <span class="elem-name">Coeficiente de regadío</span>
      <span class="elem-cant">{{infoSigpac[0].coef_regadio||0}}</span>
    </div>
    <div class=list-entry *ngIf="infoSigpac && infoSigpac[0]">
      <span class="elem-name">Incidencias</span>
      <span class="elem-cant">{{infoSigpac[0].incidencias||0}}</span>
    </div>
    <div class=list-entry *ngIf="infoSigpac && infoSigpac[0]">
      <span class="elem-name">Pendiente media</span>
      <span class="elem-cant">{{infoSigpac[0].pendiente_media||0}}</span>
    </div>
    <div class=list-entry *ngIf="infoSigpac && infoSigpac[0]">
      <span class="elem-name">Region</span>
      <span class="elem-cant">{{infoSigpac[0].region}}</span>
    </div>
    <div class=list-entry *ngIf="infoSigpac && infoSigpac[0]">
      <span class="elem-name">SRID</span>
      <span class="elem-cant">{{infoSigpac[0].srid}}</span>
    </div>
    <div class=list-entry *ngIf="infoSigpac && infoSigpac[0]">
      <span class="elem-name">Superficie</span>
      <span class="elem-cant">{{infoSigpac[0].superficie.toFixed(4)}}</span>
    </div>
    <div class=list-entry *ngIf="infoSigpac && infoSigpac[0]">
      <span class="elem-name">Uso SigPac</span>
      <span class="elem-cant" *ngIf="infoSigpac[0].uso_sigpac==='TA'">Tierras arables</span>
      <span class="elem-cant" *ngIf="infoSigpac[0].uso_sigpac==='PR'">Pasto arbustivo</span>
    </div>
  </div>
