import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/services/contact.service';
import { NotificacionDialogComponent } from '../notificacion-dialog/notificacion-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent {

  public contactForm: FormGroup;
  public errorMsg: string[] = [];
  clickSend: boolean = false;

  constructor(private location: Location, 
   private contactService: ContactService ,private formBuilder: FormBuilder,
   private dialog:MatDialog) {
    this.contactForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],

    });

  }

  ngOnInit() {
  }

  send(){
    if (this.contactForm.invalid) {
      this.openToast('Rellena todos los campos');
      this.clickSend = true;
      
      return;
    }
    let data = {
      name:this.contactForm.get('name').value,
      email:this.contactForm.get('email').value,
      subject:this.contactForm.get('subject').value,
      body:this.contactForm.get('body').value
    }
    this.contactService.sendEmail(data).subscribe(resp => {
      if(resp==false){
        this.presentAlertSent( 'Por favor intentelo más tarde','Error al enviar solicitud');
        return;
      }else{
         this.presentAlertSent(resp.message,resp.title);

      }
      this.contactForm.reset();
    });

  }

  goBack() {
    this.location.back();
    setTimeout(() => {
    }, 100);
  }
  async openToast(text_message: string) {
    this.notificacionDialog('250ms', '250ms', text_message, 1,'Error');
 
  }

  async presentAlertSent(msg,title) {
    this.notificacionDialog('250ms', '250ms', msg,1,title);
   
  }

  comprobarCampo(campo:string){
    if((this.contactForm.get(campo).invalid && this.contactForm.get(campo).touched) || this.clickSend ){
      this.errorMsg.push(campo);
      return true;
    }
    return false;
  }

  notificacionDialog(enterAnimationDuration: string, exitAnimationDuration: string, mensaje, botones,title): void {
    this.dialog.open(NotificacionDialogComponent, {
      data: { msg: mensaje, botones: botones ,title:title},
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

}
