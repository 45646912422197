import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, first, map, merge, startWith, switchMap, Observable, of as observableOf } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ElementsService } from '../../services/elements.service';
import { element } from 'protractor';
import { faCircleUser, faHourglassClock, faLockKeyhole, faChevronDown, faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { Dialog } from '@angular/cdk/dialog';
import { MatDialog } from '@angular/material/dialog';
import { EditTableComponent } from '../../shared/edit-table/edit-table.component';
import { Meta, Title } from '@angular/platform-browser';
import { InterpretationService } from 'src/app/services/interpretation.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-tablas',
  templateUrl: './tablas.component.html',
  styleUrls: ['./tablas.component.scss']
})
export class TablasComponent {
  open: boolean = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  faCircleUser = faCircleUser;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  imagenUrl: string = AppSettings.MEDIA + '/centros/';
  showFiller = false;
  sortedData = [];
  data = []
  roleAdmin: string | boolean = false;
  menu = [
    {
      nombreTabla: 'phs',
      name: 'PH'
    },
    {
      nombreTabla: 'materiaorganicas',
      name: 'Materia Orgánica'
    },
    {
      nombreTabla: 'nitroelementals',
      name: 'Nitrógeno'
    },
    {
      nombreTabla: 'cnrelacions',
      name: 'CN'
    },
    {
      nombreTabla: 'fosforos',
      name: 'Fósforo'
    },
    {
      nombreTabla: 'potasios',
      name: 'Potasio'
    },
    {
      nombreTabla: 'calcios',
      name: 'Calcio'
    },
    {
      nombreTabla: 'magnesios',
      name: 'Magnesio'
    },
    {
      nombreTabla: 'sodios',
      name: 'Sodio'
    },
    {
      nombreTabla: 'aluminios',
      name: 'Aluminio'
    },
    {
      nombreTabla: 'carbonatocalcicos',
      name: 'Carbonato cálcico'
    },
    {
      nombreTabla: 'calizaactivas',
      name: 'Caliza'
    },
    {
      nombreTabla: 'cobres',
      name: 'Cobre'
    },
    {
      nombreTabla: 'hierros',
      name: 'Hierro'
    },
    {
      nombreTabla: 'manganesos',
      name: 'Manganeso'
    },
    {
      nombreTabla: 'sulfatos',
      name: 'Sulfato'
    },
    {
      nombreTabla: 'zincs',
      name: 'Zinc'
    },



  ]
  displayedColumns: string[] = [];
  columnas = []
  type = '';
  nombreTabla = '';

  displayedColumnsAnalysis: string[] = [];
  dataSourceAnalysis: MatTableDataSource<any> = new MatTableDataSource<any>();

  displayedColumnsUsers: string[] = [];
  dataSourceUsers: MatTableDataSource<any> = new MatTableDataSource<any>();

  // editar valoración y la recomendación


  constructor(private auth: AuthenticationService, private elementsService: ElementsService, private router: Router,
    public dialog: MatDialog, private meta: Meta, private title: Title, private interpretationService: InterpretationService) {

    this.title.setTitle('Administrador - Tabla de elementos');
    if (this.meta.getTag('name=description')) {
      this.meta.updateTag({ name: 'description', content: 'Desde tabla, podrás gestionar y editar fácilmente los elementos.' });
    } else {
      this.meta.addTag({ name: 'description', content: 'Desde tabla, podrás gestionar y editar fácilmente los elementos.' });
    }
  }

  ngOnInit(): void {
    if(localStorage.getItem('tabla')){
      this.type = JSON.parse(localStorage.getItem('tabla')).tipo;
      this.nombreTabla = JSON.parse(localStorage.getItem('tabla')).nombreTabla;
      if(this.nombreTabla=='analisis'){
        setTimeout(() => {
          this.analysisView()
        }, 250);
      }else if(this.nombreTabla=='usuarios'){
        setTimeout(() => {
          this.usersView()
        }, 250);
      }
    }else{
      this.type = 'Aluminio';
      this.nombreTabla = 'aluminios';
    }
    

  }
  ngAfterViewInit(): void {


    this.cargarTabla();

    // translate mat paginator
    this.paginator._intl.itemsPerPageLabel = 'Elementos por página';
    this.paginator._intl.nextPageLabel = 'Siguiente';
    this.paginator._intl.previousPageLabel = 'Anterior';
    this.paginator._intl.firstPageLabel = 'Primera página';
    this.paginator._intl.lastPageLabel = 'Última página';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };


  }

  elementChange(element: any) {
    this.type = element.name;
    this.nombreTabla = element.nombreTabla;
    const tabla={
      'tipo':this.type,
      'nombreTabla':this.nombreTabla
    }
    localStorage.setItem('tabla',JSON.stringify(tabla))
    this.paginator.pageIndex = 0;
    this.cargarTabla();
  }

  goToAnalysis(data){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        parcel: data.parcelaId
      },
      state: {
        year: data.anoanalisis,
        history: true
      }
    };

    this.router.navigate(['/data-analysis'],navigationExtras);
  }

  analysisView(){
    this.type = 'Análisis';
    this.nombreTabla = 'analisis';
    const tabla={
      'tipo':this.type,
      'nombreTabla':this.nombreTabla
    }
    localStorage.setItem('tabla',JSON.stringify(tabla))
    this.paginator.pageIndex = 0;
    this.loadAnalysisTable();
  }

  usersView(){
    this.type = 'Usuarios';
    this.nombreTabla = 'usuarios';
    const tabla={
      'tipo':this.type,
      'nombreTabla':this.nombreTabla
    }
    localStorage.setItem('tabla',JSON.stringify(tabla))
    this.paginator.pageIndex = 0;
    this.loadUsersTable();
  }

  copyToClipboard(id) {
  }

  openDialog(id, cabecera, campo) {

    const dialogRef = this.dialog.open(EditTableComponent,
      {
        data: {
          id: id,
          cabecera: cabecera,
          campo: campo,
          nombreTabla: this.nombreTabla

        },

      }

    ).afterClosed().subscribe(() => {

      this.cargarTabla();


    })




  }

  loadAnalysisTable() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.interpretationService.getAllInterpretations({

            items: this.paginator.pageSize,
            page: this.paginator.pageIndex + 1,
          });
        }),
        map(data => {    
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data[2].value
          this.columnas =['name', 'email', 'parcel', 'year','date','estadopago'];
          this.displayedColumnsAnalysis = this.columnas;

          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.logout();
          return observableOf([]);
        })
      ).subscribe(data => this.dataSourceAnalysis.data =  data[0]);
  }

  loadUsersTable() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.interpretationService.getAllUsers(
            {

              items: this.paginator.pageSize,
              page: this.paginator.pageIndex + 1,
            }
          );
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data[2].value
          this.columnas =['name', 'email', 'nempresa', 'telephone', 'address'];
          this.displayedColumnsUsers = this.columnas;

          return data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.logout();
          return observableOf([]);
        })
      ).subscribe(data => this.dataSourceUsers.data = data[0]);
  }

  cargarTabla() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          let nombreTablaAux=this.nombreTabla;
          if(this.nombreTabla=='usuarios'){nombreTablaAux='allUsers'}
          else if(this.nombreTabla=='analisis'){nombreTablaAux='allInterpretasByAdmin'}
          
          return this.elementsService.getElementos(nombreTablaAux, {

            items: this.paginator.pageSize,
            page: this.paginator.pageIndex + 1,
          });
        }),
        map(data => {

          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];
          this.columnas = Object.keys(data['hydra:member'][0]);
          this.columnas.splice(0, 2);
          this.displayedColumns = this.columnas;

          return data['hydra:member'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.logout();
          return observableOf([]);
        })
      ).subscribe(data => this.dataSource.data = data);
  }

  buildAddressString(element){
    let address= '';
    if (element.direccion) {
      address= address + element.direccion;
    }
    if (element.cp) {
      address = address + ', ' + element.cp;
    }
    if (element.localidad) {
      address = address + '. ' + element.localidad;
    }
    if (element.provincia) {
      address = address + ', ' + element.provincia;
    }
    if (element.pais) {
      address = address + ', ' + element.pais;
    }

    return address;
  }

  logout() {
    this.auth.logout();
  }
}







