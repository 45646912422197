import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ElementsService } from '../../services/elements.service';
import { DialogCloseOptions, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-edit-table',
  templateUrl: './edit-table.component.html',
  styleUrls: ['./edit-table.component.scss']
})
export class EditTableComponent {
  myForm:FormGroup;
  resultado='';
 comprobar:boolean=false;


  //recibir los datos de la tabla del del opendialog
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,private fb:FormBuilder,private elementsService:ElementsService,
  private dialofRef:DialogRef<EditTableComponent>,) {
      this.myForm = this.fb.group({
       descripcion:['',[Validators.required]],
      })
    }
    ngOnInit(): void {
    this.myForm.controls['descripcion'].setValue(this.data.campo);
    this.resultado = this.data.campo;
  }


  cambiar(){
    if(this.myForm.valid && this.myForm.controls['descripcion'].value != this.data.campo){
      //eliminar saltos de linea
      let descripcion = this.myForm.controls['descripcion'].value.replace(/(\r\n|\n|\r)/gm, "");
      if(this.data.cabecera == "recomendacion"){
      this.elementsService.setElemento(this.data.nombreTabla,this.data.id,{"recomendacion":descripcion}).subscribe((res:any)=>{
    
        let info :any={reco:res.recomendacion,id:this.data.id}
        // this.dialofRef.close(info)
 
        
        this.resultado= res.recomendacion;
        this.comprobar=true;
        this.dialofRef.close(info)})
      }else if(this.data.cabecera == "interpretacion"){
        this.elementsService.setElemento(this.data.nombreTabla,this.data.id,{"interpretacion":descripcion}).subscribe((res:any)=>{
          let info :any={reco:res.interpretacion,id:this.data.id}
          this.resultado= res.interpretacion;
          this.comprobar=true;
          this.dialofRef.close(info)
          
        }
        )
      }
    }else{
      return this.data.campo;
      //cambiar el color del Textarea
    }
  }


  prueba(){
   if(this.comprobar){
        return this.resultado
   }
    
  }
}
