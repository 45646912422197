import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class TableGuard implements CanActivate {

  constructor(private auth:AuthenticationService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return new Observable<boolean>((observer) => {
      this.auth.getUsersMe().subscribe(user => {
        this.auth.getUser(user.id).subscribe(user => {
          observer.next(user.roles.includes('ROLE_ADMIN')||false);
        })

      });

    }
    );
  }



}
