import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-select-analisys',
  templateUrl: './select-analisys.component.html',
  styleUrls: ['./select-analisys.component.scss']
})
export class SelectAnalisysComponent {
  faArrowLeft = faArrowLeft;
  parcelId: any;

  constructor(private router: Router, private location: Location,  private route: ActivatedRoute) {
    const state=this.router.getCurrentNavigation().extras.state;
    
    if(state){
      if (state['parcel']) {
        this.parcelId= state['parcel'];
      }
    }
   }

  ngOnInit() { 
  }

  goToFree(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        // type: 'free',
        parcel: this.parcelId
      },
      state:{
        type:'free'
      }
    };
   
    this.router.navigate(['/data-taking'], navigationExtras);
  }

  goToPaid(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        // type: 'paid',
        parcel: this.parcelId
      },
      state:{
        type:'paid'
      }
    };
  
    this.router.navigate(['/data-taking'],navigationExtras);

  }

  goToPremium(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
        type: 'premium'
      }
    };
    const info={type:'premium'}
    this.router.navigate(['/data-taking'], {state: info});

  }

  goBack() {
    this.location.back();
   
  }

}
