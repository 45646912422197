import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ParcelsService } from 'src/app/services/parcels.service';
import { Location } from '@angular/common';
import { EntitysService } from 'src/app/services/entitys.service';
import { faArrowLeft} from "@fortawesome/pro-regular-svg-icons";
import { faCaretDown} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-edit-parcel',
  templateUrl: './edit-parcel.component.html',
  styleUrls: ['./edit-parcel.component.scss']
})
export class EditParcelComponent {
  faArrowLeft=faArrowLeft;
  faCaretDown=faCaretDown;

  // parcel: number;
  parcela: string;
  localidad: string;
  paraje: string;
  refcatastral: string;
  poligono: number;
  superficatastral: string;
  metodo: string = '';
  supersiembra: number;
  densidad: number;
  capaarable: number;
  prodesperada: number;
  provincia: string;
  municipio: string;
  agregado: string;
  codzona: string;
  recinto: string;
  provsigpac: number;
  munsigpac: number;
  nombre: string;
  id: string;
  analisis: any;
  users: any;
  cultivos: any[] = [];
  cultivo: string='';
  cultivoId: number;
  mostrarPagina: boolean = false;

  constructor(private auth: AuthenticationService,private entityService:
     EntitysService, private location: Location,
     /* private mapPage: MapHomePage,*/ private parcelsService:
      ParcelsService,/* public popoverController: PopoverController, 
      */private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      

      this.parcelsService.getParcelaById(params['parcel']).subscribe(parcel => {
        if(!parcel){
          this.goBack();
        }else{
          this.mostrarPagina = true;
        }
        this.parcela = parcel.parcela;
        this.localidad = parcel.localidad;
        this.paraje = parcel.paraje;
        this.refcatastral = parcel.refcatastral;
        this.poligono = parcel.poligono;
        this.superficatastral = parcel.superficatastral;
        this.supersiembra = parcel.supersiembra;
        this.densidad = parcel.densidad;
        this.capaarable = parcel.capaarable;
        this.prodesperada = parcel.prodesperada;
        this.provincia = parcel.provincia;
        this.municipio = parcel.municipio;
        this.agregado = parcel.agregado;
        this.codzona = parcel.codzona;
        this.recinto = parcel.recinto;
        this.munsigpac = parcel.munsigpac;
        this.provsigpac = parcel.provsigpac;
        this.nombre = parcel.nombre;
        this.id = parcel.id;
        this.analisis = parcel.analisis;
        this.users = parcel.users;
        this.cultivo = parcel.cultivos.split('/')[3];
        this.entityService.getCropById(this.cultivo).subscribe(crop => {
          this.cultivoId = crop.id;
          this.cultivo = crop.cultivo;
        })

        if (parcel.parcela === '[NULL]') {
          this.parcela = null;
        }
        if (parcel.localidad === '[NULL]') {
          this.localidad = null;
        }
        if (parcel.paraje === '[NULL]') {
          this.paraje = null;
        }
        if (parcel.refcatastral === '[NULL]') {
          this.refcatastral = null;
        }
        if (parcel.poligono === '[NULL]') {
          this.poligono = null;
        }
        if (parcel.superficatastral === '[NULL]') {
          this.superficatastral = null;
        }
        if (parcel.supersiembra === '[NULL]') {
          this.refcatastral = null;
        }
        if (parcel.densidad === '[NULL]') {
          this.refcatastral = null;
        }
        if (parcel.capaarable === '[NULL]') {
          this.refcatastral = null;
        }
        if (parcel.prodesperada === '[NULL]') {
          this.refcatastral = null;
        }
        if (parcel.provincia === '[NULL]') {
          this.provincia = null;
        }
        if (parcel.municipio === '[NULL]') {
          this.municipio = null;
        }
        if (parcel.agregado === '[NULL]') {
          this.agregado = null;
        }
        if (parcel.codzona === '[NULL]') {
          this.codzona = null;
        }
        if (parcel.recinto === '[NULL]') {
          this.recinto = null;
        }
        if (parcel.munsigpac === '[NULL]') {
          this.munsigpac = null;
        }
        if (parcel.provsigpac === '[NULL]') {
          this.provsigpac = null;
        }
        if (parcel.nombre === '[NULL]') {
          this.nombre = null;
        }
        if (parcel.id === '[NULL]') {
          this.id = null;
        }
        if (parcel.analisis === '[NULL]') {
          this.analisis = [];
        }
        if (parcel.users === '[NULL]') {
          this.users = null;
        }
        if (parcel.cultivos === '') {
          this.cultivo = '';
        }
        if (parcel.secano) {
          this.metodo = 'Secano';
        }
        if (parcel.regadio) {
          this.metodo = 'Regadío';
        }


        this.entityService.getCrops().subscribe(data => {
          this.cultivos=data;
          
        })
      });




    });

  }

  goBack() {
    this.location.back();
  }

  clickMethod(type: any) {
    this.metodo = type.target.value;
  }
  // type: any, id: number
  clickCrop(event:any){
    this.cultivo=event.target.value[1];
    this.cultivoId=event.target.value[0];
    // this.popoverController.dismiss();
  }

  edit() {
    this.auth.getUsersMe().subscribe(user => {
      this.auth.getUser(user.id).subscribe(userAux => {

        let parcel = {
          localidad: this.localidad,
          paraje: this.paraje,
          secano: false,
          regadio: false,
          poligono: this.poligono,
          parcela: this.parcela,
          refcatastral: this.refcatastral,
          superficatastral: this.superficatastral,
          supersiembra: this.supersiembra,
          users: 'api/users/'+userAux.id,
          analisis: this.analisis,
          densidad: this.densidad,
          capaarable: this.capaarable,
          prodesperada: this.prodesperada,
          cultivos: 'api/cultivos/'+this.cultivoId,
          provincia: this.provincia,
          municipio: this.municipio,
          agregado: this.agregado,
          codzona: this.codzona,
          recinto: this.recinto,
          provsigpac: this.provsigpac,
          munsigpac: this.munsigpac,
          nombre: this.nombre,
          id: this.id,
        }

        switch (this.metodo) {
          case 'Secano':
            parcel.secano = true;
          
            break;
          case 'Regadío':
            parcel.regadio = true;
        

            break;
          default:
            break;
        }

        this.parcelsService.putParcel(parcel).subscribe(data => {
        });
        this.location.back();
      })
    }, error => {
    this.auth.logout();
    })

  }

}
