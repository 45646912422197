import { formatDate, Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AnalysisService } from 'src/app/services/analysis.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FertilizerService } from 'src/app/services/fertilizer.service';
import { InterpretationService } from 'src/app/services/interpretation.service';
import { ParcelsService } from 'src/app/services/parcels.service';
import { faArrowLeft,} from "@fortawesome/pro-regular-svg-icons";
import { MatDialog } from '@angular/material/dialog';
import { NotificacionDialogComponent } from '../../shared/notificacion-dialog/notificacion-dialog.component';

@Component({
  selector: 'app-data-taking',
  templateUrl: './data-taking.component.html',
  styleUrls: ['./data-taking.component.scss']
})
export class DataTakingComponent {
  parcel: any;
  faArrowLeft=faArrowLeft;

  agr: number = null;
  afi: number = null;
  lim: number = null;
  arc: number = null;
  cca: number = null;
  calact: number = null;
  po: number = null;
  ka1: number = null;
  ka2: number = null;
  ph: number = null;
  ce: number = null;
  ctc: number = null;
  caa: number = null;
  mga: number = null;
  naa: number = null;
  al: number = null;
  ca: number = null;
  cu: number = null;
  p: number = null;
  fe: number = null;
  mg: number = null;
  mn: number = null;
  na: number = null;
  so: number = null;
  zn: number = null;
  b: number = null;
  moox: number = null;
  nele: number = null;
  cnc: number = null;
  cna: number = null;
  d: number = null;
  date: any;

  floorType: string = '';

  openModal: boolean = false;
  loading: boolean = false;
  type: string = '';
  auxType;
  mostrarPagina: boolean = false;
  msgType: string = '';



  constructor(private location: Location, private parcelsService: ParcelsService, private interpretationService: InterpretationService,
     private fertilizerService: FertilizerService, private auth: AuthenticationService, private analysisService: AnalysisService,
      private router: Router,  private route: ActivatedRoute,private dialog:MatDialog) { 

        const state=this.router.getCurrentNavigation().extras.state;
        
        if(state){
          if (state['type']) {
            this.type= state['type'];
            this.auxType=state['type'];
            if(this.type=='free'){
              this.msgType='Tipo de análisis: Gratuito';
          }else{
            this.msgType='Tipo de análisis: De pago';
          }
        }
        } 
      }

  ngOnInit() {


    try{
      //coger los datos del state
    this.route.queryParams.subscribe(params => {
      this.parcelsService.getParcelaById(params.parcel).subscribe(parcel => {
       if(!parcel){
        this.goBack();
       }else{
        this.mostrarPagina=true;
       }
        
        this.parcel = parcel;
      })
      
    });
  }catch(error){
    this.goBack();
  }
  }


  goBack() {
    this.location.back();
  }

  prueba(event){
  const fecha=event.target.value;
    this.date=new Date(fecha);
 
  }
  save() {
    this.loading=true;
    if (!this.ph || !this.moox || !this.nele || !this.cna || !this.p || !this.ka2 || !this.ca || !this.mg || !this.na  || !this.al
      || !this.fe || !this.so   || !this.cu || !this.mn || !this.zn || this.floorType === '' || !this.date || this.date=='' || !this.cca) {

    this.loading=false;

    this.openToast('Rellena todos los campos obligatorios', 'error');
      return
    }
    
    
    
    let analysis = {
      ph: this.ph,
      mo: this.moox,
      ne: this.nele,
      cn: this.cna,
      p: this.p,
      k: this.ka2,
      ca: this.ca,
      mg: this.mg,
      na: this.na,
      al: this.al,
      fe: this.fe,
      so: this.so,
      cu: this.cu,
      mn: this.mn,
      zn: this.zn,
      fecha: this.formatDate(this.date.toISOString().substring(0, 10)),
      parcela: this.parcel.id,
      limo: this.lim,
      arcilla: this.arc,
      // interpretacion: "string",//????
      nmuestra: "string",//que es esto
      ano: this.date.toISOString().substring(0, 4),
      // interpretagen: false,//start en false no?
      arenag: this.agr,
      arenaf: this.afi,
      carbcal: this.cca,
      caliactiv: this.calact,
      ce: this.ce,
      ctc: this.ctc,
      caasimi: this.caa,
      mgasimi: this.mga,
      naasimi: this.naa,
      boro: this.b,
      fosforoo: this.po,
      poasimilaacnh4: this.ka1,
      cncalcu: this.cnc,
      type: this.type,
      // payed: false, //se manda en falso supongo
      // Estadopago: '',//diplicado?
      tsuelo: this.floorType,
      // estadopago: ''
    }


 
    
    this.analysisService.postDataAnalysis(analysis).subscribe(data => {
      
      if(data.code==400 || data==false){
        this.loading=false;
        
        if(data.message=='"El valor Fe no es correcto"'){
          this.openToast('Los valores introducidos son incorrectos', data.title||'error');
        }else{
        this.openToast(data.message ||'No se pudo guardar el analisis', data.title||'error');
        }
      }else{
        this.auth.getUsersMe().subscribe(user => {
        let navigationExtras: NavigationExtras = {
          state: {
            email: user.email,
            analysis: data.id,
            parcel: this.parcel.id,
            fromPay: true,
            year: this.date.toISOString().substring(0, 4),
          }
        };
        this.loading=false;

        if (this.type === 'free') {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              parcel: this.parcel.id,
            },
            state: {
              estadoNuevo: true,
              history: true,
              year: this.date.toISOString().substring(0, 4),
            }
          };
          this.router.navigate(['/data-analysis'], navigationExtras);
        }
        if (this.type === 'paid') {
          this.router.navigate(['/providers'], navigationExtras);

        }
      },error=>{
        this.auth.logout();
      })
      }

    });

  }

  formatDate(date: string) {
    let year = date.substring(0, 4);
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);
    return day + '-' + month + '-' + year;
  }
  
  async openToast(text_message: string,error) {
    const enterAnimationDuration = '150ms';
    const exitAnimationDuration = '150ms';
 

    const dialogRef = this.dialog.open(NotificacionDialogComponent, {
      width: '250px',
      data: { msg: text_message, botones: 1, title: error },
      enterAnimationDuration,
      exitAnimationDuration
    });
    
  }

}





// no esta cogiendo el tipo