<h1 mat-dialog-title >Introduce tu email:</h1>
<div mat-dialog-content>

  <form [formGroup]="emailForm"  (ngSubmit)="enviarDatos()" >

    <div  class="content-form-email-dialog">
      <input id="email"  formControlName="email" id="email"  placeholder="Email" type="email"  [ngClass]="{error:emailForm.get('email').errors!=null && !init}">
    </div>

  <div  class="action content-form-email-dialog">
    <!-- <button mat-button  class="different" (click)="onNoClick()">Cancelar</button> -->
    <button mat-button id="envio" (click)="validar()" [mat-dialog-close]="emailForm.get('email').value"  >Enviar</button>
  </div>
  </form>
  </div>
