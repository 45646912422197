import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colName'
})
export class ColNamePipe implements PipeTransform {

  transform(value: string): string { 
    if(value == 'recomendacion'){
      return 'Recomendación'
    }else if(value == 'interpretacion'){
      return 'Interpretación'
    }else if(value == 'clasificacion'){
      return 'Clasificación'
    }else{
      return value;
    }
   
  }

}
