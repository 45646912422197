import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, retry, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  getData(url: string, single: boolean = false, retryNumber: number = 3) {
    return this.http.get<any>(url).pipe(
      map(
        (data) => {
          if(data) {
            if(single) {
              return data[0];
            } else {
              return data;
            }
          } else {
            return data;
          }
        }
      ),
      retry(retryNumber),
      catchError(this.handleError)
    );
  }

  getDataHydra(url: string) {
    return this.http.get<any>(url).pipe(
      map(data => {
        return data['hydra:member'];
      }),
      retry(3),
      catchError(this.handleError)
    );
  }


  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
    
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
