import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { AppSettings } from '../app-settings';


@Injectable({
  providedIn: 'root'
})
export class InterpretationService {

  constructor(private http: HttpClient) { }

  postInterpretation(idAnalisis:any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/generateinterpretacion/${idAnalisis}`;
    return this.http.post<any>(url, idAnalisis, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  getInterpretation(id: any) {
    return this.http.get<any>(AppSettings.API_URI + '/api/datosinterpretas/'+id).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    );
  }

  getInterpretationParcela(data: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/datosinterpretaparcela`;
    return this.http.post<any>(url, data, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  getInterpretationsUser(userId: any){
    return this.http.get<any>(AppSettings.API_URI + '/api/allinterpretasbyuser/'+userId).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    );
  }
  getElementos(nombreTabla:String,{items,page}){
  
    
    const url = `${ AppSettings.API_URI }/api/${nombreTabla}?page=${page}&items=${items}`;
 return   this.http.get<any>(url).pipe(
      map((data: any) => {
        return data;
      }
    )
    )

//     1	Muy bajo	50	Muy bajo, habitualmente asociado a suelos muy calizos o yesosos	
// Optimo para muchos cultivos


  }
  getAllInterpretations({items,page}) {
    return this.http.get<any>(AppSettings.API_URI + `/api/allInterpretasByAdmin?page=${page}&items=${items}`).pipe(
      map(data => {
        return this.convertToArray(data);
      }, error => {
        return false;
      })
    );
  }

  getAllUsers({items,page}) {
    return this.http.get<any>(AppSettings.API_URI + `/api/allUsers?page=${page}&items=${items}`).pipe(
      map(data => {
        return this.convertToArray(data);
      }, error => {
        return false;
      })
    );
  }

  convertToArray(item: any) {
   let array2 = [];
   for (let key in item) {

     if (typeof item[key] === 'object') {
       array2.push(item[key]);
     } else {
       let cosa = {
         prop: key,
         value: item[key]
       }
       array2.push(cosa);
     }
   }
   return array2;
 }
}
