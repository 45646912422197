import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class FertilizerService {

  constructor(private http: HttpClient) { }

  postAbonos(idAnalisis:any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/calculoabonado/${idAnalisis}`;
    return this.http.post<any>(url, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }

  getAbonos(id: any) {
    return this.http.get<any>(AppSettings.API_URI + '/api/datoscalculoabonado/'+id).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    );
  }

  getAbonadoParcela(data: any) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })};
    const url = `${ AppSettings.API_URI }/api/datosabonadobyparcela`;
    return this.http.post<any>(url, data, formHeaders).pipe(
      map(data => {
        if(data) {
          return data;
        } else return of(false);
      }), catchError((err) => {
        return of(false);
      })
    );
  }
}
