
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// const Platform:any
@Component({
  selector: 'app-payment-ko',
  templateUrl: './payment-ko.component.html',
  styleUrls: ['./payment-ko.component.scss']
})
export class PaymentKoComponent {

  subscription: any;
  idAnalysis: string= '';

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      
      if (params['analysis']) {
        this.idAnalysis = params['analysis'];
      }
    });
  }
  // ionViewDidEnter() {
  //   this.subscription = this.platform.backButton.subscribeWithPriority(9999, () => {
  //     // do nothing
  //   })
  // }

  // ionViewWillLeave() {
  //   this.subscription.unsubscribe();
  // }

  back(){
    this.router.navigateByUrl('/perfil');
  }

}
