import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EntitysService } from 'src/app/services/entitys.service';
import { ParcelsService } from 'src/app/services/parcels.service';
import { faArrowLeft} from "@fortawesome/pro-regular-svg-icons";
import { faCaretDown} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-crear-parcel',
  templateUrl: './crear-parcel.component.html',
  styleUrls: ['./crear-parcel.component.scss']
})
export class CrearParcelComponent {
  faArrowLeft=faArrowLeft;
  faCaretDown=faCaretDown;

  
  parcela: number = null;
  localidad: string;
  nombre: string = '';
  paraje: string;
  refcatastral: string;
  poligono: number = null;
  superficatastral: string;
  metodo: string = '';
  supersiembra: number = null;
  densidad: number = null;
  capaarable: number = null;
  prodesperada: number = null;
  provincia: string;
  municipio: string;
  agregado: number = null;
  codzona: number = null;
  recinto: number = null;
  provsigpac: number = null;
  munsigpac: number = null;

  cultivos: any[] = [];
  cultivo: string = '';
  cultivoId: number;


  constructor(private auth: AuthenticationService,private entityService:
     EntitysService, private location: Location,
     /* private mapPage: MapHomePage,*/ private parcelsService:
      ParcelsService,/* public popoverController: PopoverController, 
      */private route: ActivatedRoute) { }

  ngOnInit() {
   
     
    this.entityService.getCrops().subscribe(data => {
      
      this.cultivos = data;

    })

  }

  goBack() {
    this.location.back();
  }

  clickMethod(type: any) {
    this.metodo = type.target.value;
  }
  // type: any, id: number
  clickCrop(event:any){
    this.cultivo=event.target.value[1];
    this.cultivoId=event.target.value[0];
    // this.popoverController.dismiss();
  }

  create() {
    if (this.nombre == '' || this.cultivo=='' || this.metodo == '' || this.supersiembra== null || this.densidad== null || this.capaarable== null || this.prodesperada== null || this.provsigpac== null || this.munsigpac== null || this.poligono== null || this.parcela== null || this.agregado== null || this.codzona== null || this.recinto== null) {
    // this.openToast('Rellena todos los campos obligatorios');
    
      return
    }
    this.auth.getUsersMe().subscribe(user => {
      this.auth.getUser(user.id).subscribe(userAux => {
        let parcel = {
          localidad: this.localidad,
          paraje: this.paraje,
          secano: false,
          regadio: false,
          poligono: this.poligono,
          parcela: this.parcela,
          refcatastral: this.refcatastral,
          superficatastral: this.superficatastral,
          supersiembra: this.supersiembra,
          users: 'api/users/' + userAux.id,
          analisis: [],
          densidad: this.densidad,
          capaarable: this.capaarable,
          prodesperada: this.prodesperada,
          cultivos: 'api/cultivos/' + this.cultivoId,
          provincia: this.provincia,
          municipio: this.municipio,
          agregado: this.agregado.toString(),
          codzona: this.codzona.toString(),
          recinto: this.recinto.toString(),
          provsigpac: this.provsigpac,
          munsigpac: this.munsigpac,
          nombre: this.nombre
        }

        switch (this.metodo) {
          case 'Secano':
            parcel.secano = true;
            break;
          case 'Regadío':
            parcel.regadio = true;

            break;
          default:
            break;
        }
        this.parcelsService.postParcel(parcel).subscribe(data => {
          
          if (data) {

            this.location.back();
          }
        });
      })
    }, error => {
      this.auth.logout();
      
    })



  }
}
