import { Component, EventEmitter, Output } from '@angular/core';
import { faClose } from "@fortawesome/pro-regular-svg-icons";


@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent {
  faClose = faClose;

  @Output() close = new EventEmitter();
  //emiter boolean

  constructor() { }


  cerrar(){

    this.close.emit('');
  }

}
