import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InterpretationService } from 'src/app/services/interpretation.service';
import { faArrowLeft, } from "@fortawesome/pro-regular-svg-icons";
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent {
  faArrowLeft = faArrowLeft
  entries: any[] = [];
  cargando: boolean = true;

  constructor(private interpretationService: InterpretationService, private auth: AuthenticationService, private router: Router, private location: Location, private meta: Meta, private title: Title) {
    this.cargando = true;
    this.title.setTitle('Histórico - Analiza tu parcela y mejora tus cultivos.');
    if (this.meta.getTag('name=description')) {
      this.meta.updateTag({ name: 'description', content: 'En Fertismart puedes ver el historial de análisis de tus parcelas. Descubre cómo mejorar tus cultivos con información detallada y precisa.' });
    } else {
      this.meta.addTag({ name: 'description', content: 'En Fertismart puedes ver el historial de análisis de tus parcelas. Descubre cómo mejorar tus cultivos con información detallada y precisa.' });
    }

  }

  ngOnInit() {
    this.auth.getUsersMe().subscribe(user => {
      this.interpretationService.getInterpretationsUser(user.id).subscribe(data => {

        if (data) {
          if (data == 'Este usuario no tiene parcelas') {
            this.entries = [];

          } else {
            this.entries = this.convertToArray(data);
          }
          this.cargando = false;
        } else {
          this.entries = [];
          this.cargando = false;

        }
      })


    })
  }

  goBack() {
    // this.router.navigate(['/perfil']);
    this.location.back();
    // this.location.back();
    // this.location.hostname;
  }

  goToAnalysis(data: any) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        parcel: data.parcelaId
      },
      state: {
        year: data.anoanalisis,
        history: true
      }
    };

    const info={ year: data.anoanalisis};

    this.router.navigate(['/data-analysis'],navigationExtras);
  }

  convertToArray(item: any) {
    let array2 = [];
    for (let key in item) {

      if (typeof item[key] === 'object') {
        array2.push(item[key]);
      } else {
        let cosa = {
          prop: key,
          value: item[key]
        }
        array2.push(cosa);
      }
    }
    return array2;
  }


}
