import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AnalysisService } from 'src/app/services/analysis.service';
import { ParcelsService } from 'src/app/services/parcels.service';
import { VariablesService } from 'src/app/services/variables.service';
// import { faArrowLeft,} from "@fortawesome/pro-regular-svg-icons";

import { faArrowLeft, faPenCircle, faClipboardList, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { MatDialog } from '@angular/material/dialog';
import { NotificacionDialogComponent } from '../../shared/notificacion-dialog/notificacion-dialog.component';
import { SigpacService } from '../../services/sigpac.service';



@Component({
  selector: 'app-parcel-detail',
  templateUrl: './parcel-detail.component.html',
  styleUrls: ['./parcel-detail.component.scss']
})
export class ParcelDetailComponent {
  faArrowLeft = faArrowLeft
  faPenCircle = faPenCircle;
  faSearch = faSearch;
  faClipboardList = faClipboardList;
  faCircleExclamation = faCircleExclamation;
  analice: boolean = false;
  showResume: boolean = false;



  parcelSelected: any;
  infoSigpac: any=undefined;

  analysis: any;

  constructor(private location: Location, private analysisService: AnalysisService,
    private variableService: VariablesService, private router: Router,

    public dialog: MatDialog,
     /*private mapPage: MapHomePage,*/ private route: ActivatedRoute, private parcelService: ParcelsService, private sigpacService: SigpacService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {



      let split = params['parcel'].split('.');
      let data = {
        provincia: split[0],
        municipio: split[1],
        parcela: split[2],
        poligono: split[3],
        id: split[4]
      }

      this.parcelService.getParcelaById(data.id).subscribe(parcel => {
        if (parcel.code == 404 || parcel.code == 403 || parcel == false) {
          this.goBack();
          return;
        }
        this.parcelSelected = parcel;

        



        this.variableService.setRecentParcel(data.provincia + '.' + data.municipio + '.' + data.parcela + '.' + data.poligono);
        if (this.parcelSelected.analisis && this.parcelSelected.analisis.length !== 0) {
          this.showResume=false;
          if (this.parcelSelected.users) {
            let id = this.parcelSelected.analisis[0].split('/');


            this.analysisService.getAnalysis(id[3]).subscribe(data => {
              this.analysis = data;
              this.showResume=true;
            })

          } else {


            this.analysisService.getAnalysis(this.parcelSelected.analisis[0]).subscribe(data => {
              this.analysis = data;
              this.showResume=true;

            })
          }
        }
        this.showResume=true;
        this.sigpacService.getSigPacData(parcel.recinto, split[1], parcel.agregado, parcel.codzona, split[3], split[2], split[0]).subscribe(data => {
          this.infoSigpac = data;
        })
      })
    });

  }

  goToDataTaking() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        parcel: this.parcelSelected.id
      }
    };
    const info = { parcel: this.parcelSelected.id };
    this.router.navigate(['/select-analysis'], { state: info });
    this.analice = true;

  }

  goToProviders() {

    this.router.navigateByUrl('/providers');
  }

  goEdit() {

    let navigationExtras: NavigationExtras = {
      queryParams: {
        parcel: this.parcelSelected.id
      }
    };
    this.router.navigate(['/edit-parcel'], navigationExtras);
  }

  goToAnalysis() {

    let navigationExtras: NavigationExtras = {
      queryParams: {
        parcel: this.parcelSelected.id,

      },
      state: {
        year: this.analysis.ano
      }
    };

    this.router.navigate(['/data-analysis'], navigationExtras);
  }

  goBack() {
    // this.location.back();
    this.router.navigate(['/parcelas']);
  }




}
