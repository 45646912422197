<div class="nav-header nav-header-padding">
  <nav #nav class="header navbar navbar-expand-md nav-expand fixed-top p-0 headerScroll headerTransparent"
    [ngClass]="isFixed">
    <div class="container d-flex align-items-center">
      <a class="navbar-brand logo-menu" href="home#" (click)="cerrar()">Fertismart</a>
      <button  class="navbar-toggler " type="button" (click)="toggleNavbar = !toggleNavbar" data-bs-toggle="collapse"
        data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false"
        aria-label="Toggle navigation">
        <span #buttonNav class="navbar-toggler-icon" ></span>
      </button>
      <div class="collapse navbar-collapse  justify-content-md-between " id="navbarColor01">
        <ul class="navbar-nav">
          <li class="nav-item" (click)="cerrar()">
            <a class="nav-link" href="home#first-tb">Características</a>
          </li>
          <li class="nav-item" (click)="cerrar()">
            <a class="nav-link" href="home#price-tb">Precio</a>
          </li>

          <li class="nav-item" (click)="cerrar()">
            <a class="nav-link" href="home#download-app">Descargate la app</a>
          </li>
          <!-- <li class="nav-item" *ngIf="login">
            <a class="nav-link"(click)="cerrar()" href="home#price-tb" >Informe</a>
          </li> -->
        </ul>
        <hr>
        <div class="navbar-right" (click)="cerrar()">
          <fa-icon [icon]="faCircleUser" class="icon-login-header"></fa-icon> | <a *ngIf="!login"
            (click)="openDialog()">Iniciar sesión</a>
          <a *ngIf="login" (click)="goToProfile()">Ir al perfil</a>
        </div>
      </div>
    </div>
  </nav>
</div>
