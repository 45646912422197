import { Component } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent {

  ngOnInit(): void {
    setTimeout(() => {
    window.scrollTo(0, 0);
    }, 100);
  }
 

}
