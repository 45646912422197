import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {


   private parcelSelect = new BehaviorSubject<number>(null);
   public parcelStorage = this.parcelSelect.asObservable();

   private recentParcels = new BehaviorSubject<string[]>(null);
   public recentParcelsStorage = this.recentParcels.asObservable();

   private abonosSelect = new BehaviorSubject<any>(null);
   public abonosStorage = this.abonosSelect.asObservable();

   private placeSelect = new BehaviorSubject<string>(null);
   public placeStorage = this.placeSelect.asObservable();

   private breakpointSelect = new BehaviorSubject<number>(null);
   public breakpointStorage = this.breakpointSelect.asObservable();

   private rememberPassSelect = new BehaviorSubject<boolean>(null);
   public rememberPassStorage = this.rememberPassSelect.asObservable();


  constructor() { }

  getRememberPass() {
    return this.rememberPassSelect.getValue();
  }

  setRememberPass(rp: any) {
    this.rememberPassSelect.next(rp);
  }

  getBreakpoint() {
    return this.breakpointSelect.getValue();
  }

  setBreakpoint(bp: any) {
    this.breakpointSelect.next(bp);
  }

  getPlace() {
    return this.placeSelect.getValue();
  }

  setPlace(place: any) {
    this.placeSelect.next(place);
  }

  getAbonos() {
    return this.abonosSelect.getValue();
  }

  setAbonos(abonos: any) {
    this.abonosSelect.next(abonos);
  }

  getParcel() {
    return this.parcelSelect.getValue();
  }

  setParcel(idParcel: any) {
    this.parcelSelect.next(idParcel);
  }


  getRecentParcel() {
    return this.recentParcels.getValue();
  }

  setRecentParcel(parcelId: any) {
    let recents = this.recentParcels.getValue();
    if (recents!== null) {
      if (recents.length===5) {
        if (!recents.includes(parcelId)) {
          recents.pop();
          recents.unshift(parcelId);
        }else{
          if (recents[0]!==parcelId) {
            let index = recents.indexOf(parcelId);
            parcelId.splice(index, 1);
            recents.unshift(parcelId);
          }
        }
      }else{
        if (!recents.includes(parcelId)) {
          recents.unshift(parcelId);
        }else{
          if (recents[0]!==parcelId) {

            let index = recents.indexOf(parcelId);
            recents.splice(index, 1);
            recents.unshift(parcelId);

          }
        }
      }
    }else{
      recents=[];
      recents.push(parcelId);

    }
    this.recentParcels.next(recents);
  }


}
