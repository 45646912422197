import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-notificacion-dialog',
  templateUrl: './notificacion-dialog.component.html',
  styleUrls: ['./notificacion-dialog.component.scss']
})
export class NotificacionDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { msg: string, botones: number,title:string }) { 
    
  }

}
