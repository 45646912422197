import { Component, Input } from '@angular/core';
import { AnalysisService } from 'src/app/services/analysis.service';
 import { faBarcode,faDrawSquare,faCloudDrizzle,faSprinkler,faMapLocation} from "@fortawesome/pro-regular-svg-icons";
//con fa
import { faCircle} from "@fortawesome/pro-solid-svg-icons";
 @Component({
  selector: 'app-parcel-resume',
  templateUrl: './parcel-resume.component.html',
  styleUrls: ['./parcel-resume.component.scss']
})
export class ParcelResumeComponent {
  iconos={

    faBarcode,
    faCircle,
    faDrawSquare,
    faCloudDrizzle,
    faSprinkler,
    faMapLocation,

  }

  @Input() parcel: any;
  analysis: any;
  constructor(private analysisService: AnalysisService) {  
  }

  ngOnInit() {
    if (this.parcel.analisis && this.parcel.analisis.length !== 0) {
      if (this.parcel.users) {
        let id = this.parcel.analisis[0].split('/');
        

        this.analysisService.getAnalysis(id[3]).subscribe(data => {
          this.analysis = data;
          

        })
      } else {
        

       this.analysisService.getAnalysis(this.parcel.analisis[0]).subscribe(data => {
        this.analysis=data;
        
        

       })
      }
    }
  }
}
