<div class="container">

        <div class="center">
          <fa-icon [icon]="['far','circle-check']"></fa-icon>
          <span>
            El pago se ha completado correctamente
          </span>
        </div>
        <button (click)="back()" class="btn-save"  expand="block" fill="clear" shape="round">
          Volver
        </button>

      
</div>
<!-- //railway netlify -->